<template>
  <div>
    <el-row :gutter="20">
          <el-col :span="25">
            <div>
              <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="标题: ">
                  <el-input v-model="selectTitle" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                  <el-select v-model="selectStatus" :label="1" placeholder="请选择状态">
                    <el-option v-for="item in statusList" :key="item.code" :label="item.name" :value="item.name">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-date-picker value-format="yyyy-MM-dd" @change="getList"  @input="$forceUpdate()"
                      v-model="selectStartDate" type="daterange" ange-separator="至"  start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
                  <el-button type="primary" @click="addPurchaseVisible" icon="el-icon-plus" v-if="this.roleName === '采购专员'">添加</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
    </el-row>
     <!-- 添加 -->
        <el-dialog title="添加需求类型" :visible.sync="addDialogVisible" width="60%" :close="addDialogClose" :close-on-click-modal="false">
           <el-form :model="editDialogForm" :rules="addDialogFormRules" ref="editDialogForm" label-width="100px" class="dialog_form">
                <el-form-item label="需求模板"  prop="selectTemplateId"   >
                    <el-select v-model="editDialogForm.selectTemplateId" class="form-select" clearable filterable placeholder="请选择配件">
                    <el-option v-for="item in selectTemplateName" :key="item.TemplateId" :label="item.TemplateName" :value="item.TemplateId">
                    </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="需求类型" prop="selectProcurementDemandTypeNewsId"   >
                    <el-select v-model="editDialogForm.selectProcurementDemandTypeNewsId" class="form-select" clearable filterable placeholder="请选择需求类型">
                    <el-option v-for="item in selectProcurementDemandType" :key="item.id" :label="item.ProcurementDemandTypeName" :value="item.NewsId">
                    </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="业务人员" prop="selectSubscriptionPersonNewsId"   >
                    <el-select filterable v-model="editDialogForm.selectSubscriptionPersonNewsId" class="form-select" clearable filterable placeholder="请选择业务人员">
                    <el-option v-for="item in selectSubscriptionPersonName" :key="item.id" :label="item.SubscriptionPersonName" :value="item.NewsId">
                    </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="需求标题" prop="procurementDemandTitle"   >
                  <el-input v-model="editDialogForm.procurementDemandTitle"  placeholder="请输入需求标题"  ></el-input>
                </el-form-item>
                <el-form-item label="截至日期" prop="selectDate"   >
                    <!-- <el-date-picker style="width:100%;" v-model="editDialogForm.selectDate" type="date" value-format="yyyy-MM-dd" @change="getList"  @input="$forceUpdate()" placeholder="选择日期"></el-date-picker> -->
                     <el-date-picker style="width:100%;" v-model="editDialogForm.selectDate" type="datetime" placeholder="选择日期时间">
              </el-date-picker>
                  </el-form-item>
                  <el-form-item label="描述说明">
                    <el-input type="textarea" v-model="editDialogForm.ProcurementDemandDesc" ></el-input>
                </el-form-item>
          </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>
        </el-dialog>
  </div>
</template>

<script>
  export default {
    name:'',
    data () {
        return{
            //搜索
            formInline: {
                user: '',
                region: ''
            },
            //添加
            editDialogForm:{
               selectTemplateId: 1,
               selectProcurementDemandTypeNewsId: '', //'请选择需求类型'
               selectSubscriptionPersonNewsId: '',
               procurementDemandTitle: '',
                selectDate: '',
                ProcurementDemandDesc: '',
            },
            addDialogVisible: false,//添加
            userNewsId: '',
            selectProcurementDemandType: {},//'请选择需求类型'
            procurementDemandCount: 0,
            quotateTimes: 1,
            startDate: '2023-01-01',
            endDate: '2046-06-08',
            // templateList: [],
            selectTemplateName: [], //'请选择需求模板'
            selectTemplateNames: '', //'请选择需求模板'
            // subscriptionPersonList: [],
            selectSubscriptionPersonName: {},//'请选择业务人员'
            //查询
            selectTitle: '',
            statusList: [{
                code: '001',
                name: '全部'
              }, {
                code: '002',
                name: '待审核'
              }, {
                code: '003',
                name: '已审核'
              }, {
                code: '004',
                name: '已发布'
              },
              {
                code: '005',
                name: '已截止'
              }, {
                code: '006',
                name: '已完成'
              },
              {
                code: '003',
                name: '已作废'
              },
            ],
            selectStartDate: '',
            roleName:'',
            selectStatus: '全部',
            //表单验证
            addDialogFormRules: {
              selectTemplateId: [
                   { required: true, message: '请选择需求模板', trigger: 'change' }
                ],
              selectProcurementDemandTypeNewsId: [
                  { required: true, message: '请选择需求类型', trigger: 'change' }
                ],
              selectSubscriptionPersonNewsId: [
                { required: true, message: '请选择需求类型', trigger: 'change' }
              ],
              procurementDemandTitle: [
                { required: true, message: '请输入需求标题', trigger: 'blur' },
                {
                  min: 1,
                  max: 50,
                  message: "长度在 1 到 50 个字符",
                  trigger: "blur",
                },
              ],
              selectDate: [
                { required: true, message: "请选择截至日期时间", trigger: "blur" },
              ],
        
            },
        }
   },
   created(){
      this.userNewsId =  window.sessionStorage.getItem("userNewsId");
      this.roleName =  window.sessionStorage.getItem("roleName");
      this.supplierNewsId =  window.sessionStorage.getItem("supplierNewsId");
      this.getTestTime()
   },
   methods: {
    async onSubmit(){
      let that = this;
      let url = '';
       let queryInfo = {};
    if(that.roleName != '供应商'){
        //console.log(that.roleName) 
        url = 'ProcurementDemand/GetListByKey';
        queryInfo = {
            title: that.selectTitle,
						status: that.selectStatus,
						startDate: that.selectStartDate[0],
						endDate: that.selectStartDate[1],
						userNewsId: that.userNewsId,
						roleName:that.roleName,
						supplierNewsId:that.supplierNewsId,
        }
        
      }else{
         url='ProcuredDemandSupplier/GetListByKey';
        queryInfo={
						title: that.selectTitle,
						startDate: that.selectStartDate[0],
						endDate: that.selectStartDate[1],
						supplierNewsId:that.supplierNewsId,
					}

      }
				await that.$http
          .get(url,{params: queryInfo})
					.then(
          (res) => {
            //console.log(res)
            if (res.data.Code !== "200") return that.$message.error(res.data.Message);
              this.$emit('showList');
            
          },
          (res) => {
            that.$message.error("远程获取数据失败");
          }
        );
    },
    addPurchaseVisible(){
      this.getTypeList();
      this.getTemplateList();
      this.getSubscriptionPersonList();
        this.addDialogVisible = true
    },
    // 取消添加
    addDialogClose() {
      this.$refs.editDialogForm.resetFields();
     this.addDialogVisible = false
    },
    // 添加
    saveAdd() {
      this.$refs.editDialogForm.validate((valid) => {
        if (!valid) return this.$message.error("关键信息不能为空");
        let selectDateExe = this.editDialogForm.selectDate;
        this.transformTime(selectDateExe);
        this.submitAdd();
      });
       
    },
      transformTime(selectDateExe) {
        var d = new Date(+selectDateExe+8*3600*1000);
        const sou = d.toISOString().slice(0,19).replace("T"," ")
        console.log(sou)
        this.editDialogForm.selectDate = sou
    },
    async submitAdd() {
      let that = this
      let queryInfo = {
          UserNewsId: that.userNewsId,
					TemplateId:that.editDialogForm.selectTemplateId,
					SubscriptionPersonNewsId:that.editDialogForm.selectSubscriptionPersonNewsId,
					ProcurementDemandTypeNewsId: that.editDialogForm.selectProcurementDemandTypeNewsId,
					ProcurementDemandTitle: that.editDialogForm.procurementDemandTitle,
					ProcurementDemandExpirationDateTime: that.editDialogForm.selectDate,
					ProcurementDemandDesc: that.editDialogForm.ProcurementDemandDesc,
					QuotateTimes: that.quotateTimes,
      }
      await this.$http
        .post("ProcurementDemand/AddObj", queryInfo)
        .then(
          (res) => {
            
            if (res.data.Code !== "200") {
              return this.$message.error(res.data.Message);
            }
            if (this.$refs.addDialogFormRef) {
              this.$refs.addDialogFormRef.resetFields();
            }
            this.$emit('addDataList')
            this.addDialogVisible = false;
            this.$refs.editDialogForm.resetFields();
            this.$message.success(res.data.Message);
            
          },
          (res) => {
            this.$message.error("远程操作失败或网络问题");
          }
        );
    },
    //获取需求类型列表
			async getTypeList() {
				let that = this;
				await that.$http
          .post('Template/GetListByNoPage',{params: that.queryInfo})
					.then(
          (res) => {
            //console.log(res);
            if (res.data.Code !== "200")
              return that.$message.error(res.data.Message);
              
            that.selectTemplateName = res.data.Obj;
           
          },
          (res) => {
            that.$message.error("远程获取数据失败");
          }
        );
					

			},
    //获取需求模板列表
			async getTemplateList() {
				let that = this;
				await that.$http
          .post('ProcurementDemandType/GetListByNoPage',{params: that.queryInfo})
					.then(
          (res) => {
            //console.log(res);
            if (res.data.Code !== "200")
              return that.$message.error(res.data.Message);
              
            that.selectProcurementDemandType = res.data.Obj;
          },
          (res) => {
            that.$message.error("远程获取数据失败");
          }
        );

			},
    	//获取需求业务人员
			async getSubscriptionPersonList() {
				let that = this;
        let query = { IsEnabled : true};
				await that.$http
          .post('SubscriptionPerson/GetListByNoPage', query)
					.then(
          (res) => {
            //console.log(res);
            if (res.data.Code !== "200")
              return that.$message.error(res.data.Message);
            that.selectSubscriptionPersonName = res.data.Obj;
           // console.log(that.selectSubscriptionPersonName);
          },
          (res) => {
            that.$message.error("远程获取数据失败");
          }
        );
			
			},
       // 默认 当日
      getTestTime () {
        //console.log('111')
        let isDate = new Date()
        let sTime = `${isDate.getFullYear()}-${isDate.getMonth() }-${isDate.getDate()}`
        let sTimes = `${isDate.getFullYear()}-${isDate.getMonth()+1 }-${isDate.getDate()}`
        let eTime = `${isDate.getFullYear()}-${isDate.getMonth() + 1}-${isDate.getDate()}`
        sTime = `${sTime} 00:00:00`
        eTime = `${eTime} 23:59:59`
        this.selectStartDate = [new Date(sTime), new Date(eTime)] // 显示的默认时间
        this.editDialogForm.selectDate = new Date(sTimes)
      },
		},//method end
  }
</script>

<style scoped>
  
</style>