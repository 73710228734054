<template>
  <div>
    <div>
        <el-form :model="from" :rules="addDialogFormRules" ref="addDialogForm" label-width="100px" class="dialog_form">
         <el-form-item label="发布审核意见:"  prop="verifyResult" label-width="130px">
              <el-select v-model="from.verifyResult" :label="1" :selected="1" placeholder="请选择审核意见" style="width:100%" >
                    <el-option v-for="item in resultList" :key="item.code" :label="item.name" :value="item.name">
                    </el-option>
                  </el-select>
                </el-form-item>
          <el-form-item label="审核描述说明" label-width="130px" prop="verifyDesc">
                <el-input type="textarea" v-model="from.verifyDesc"></el-input>
           </el-form-item>
    </el-form>
   </div>
   <div class="footer-div" >
     <span class="dialog-footer">
            <el-button @click="checkDialogClose">取 消</el-button>
            <el-button type="primary" @click="saveAddCheck">确 定</el-button>
     </span>
   </div>
   
  </div>
</template>

<script>
  export default {
    name:'',
    props:['DemandContent' ],
    data () {
        return{
            addDialogForm:{},
           resultList: [{
              code: '001',
              name: '同意'
            }, {
              code: '002',
              name: '修正'
            },  ],
          from:{
            verifyResult:'',
            verifyDesc:'',
          },
                userNewsId:'',
                addDialogFormRules:{ 
                   verifyResult: [
                      { required: true, message: '请选择审核意见', trigger: 'change' }
                    ],
                    verifyDesc: [
                      { required: true, message: '请填写描述说明', trigger: 'blur' }
                    ]
                }
        }
        
   },
   created(){
      this.userNewsId =  window.sessionStorage.getItem("userNewsId");
        this.from.verifyResult = this.DemandContent.VerifyResult;
            this.from.verifyDesc = this.DemandContent.VerifyDesc;
      
   },
   methods:{
    checkDialogClose(){
        this.$emit('checkFunc')
        this.$refs.addDialogForm.resetFields();
        console.log(this.DemandContent)
        console.log( this.from.verifyResult)
        console.log(this.from.verifyDesc)
    },
    saveAddCheck(){
      this.$refs.addDialogForm.validate((valid) => {
            if (!valid) return this.$message.error("关键信息不能为空");
            if(this.from.verifyResult!='同意'){
              this.getAccessToken();
              return;
            }
               this.$confirm("您确实要发布此记录吗, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                //执行删除方法
                this.getAccessToken();
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消操作",
                });
              });
              
      });
        
    },
    async getAccessToken() {
        await this.$http.post("User/getAccessToken").then(
            (res) => {
             if (res.data.Code !== "200")
              return that.$message.error(res.data.Message);
              this.saveAdd(res.data.Obj)
              // this.$emit('checkFunc')
          },
          (res) => {
            that.$message.error("远程获取数据失败");
          })
    },
    async saveAdd(accessToken){
        let that = this
       let data = {
				id:that.DemandContent.id,
				VerifyUserNewsId: that.userNewsId,
				VerifyResult: that.from.verifyResult,
				VerifyDesc: that.from.verifyDesc,
				AccessToken: accessToken
			}
        await that.$http.post("ProcurementDemand/checkObj", data).then(
            (res) => {
            if (res.data.Code !== "200") {
                return this.$message.error(res.data.Message);
            }
            this.$emit('checkFunc')
            this.$message.success(res.data.Message);
            this.$refs.addDialogForm.resetFields();
            },
            (res) => {
            this.$message.error("远程操作失败或网络问题");
            })
    }
   }
  }
</script>

<style scoped>
.footer-div{
    height: 30px;
}
  .dialog-footer{
    float: right;
  }
</style>