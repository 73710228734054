<template>
  <div>
    <div>
      <div>
        <span :style="{ lineHeight: '40px' }">请选择可投标的供应商:</span>
        <el-button type="primary" @click="addDistributeVisible" :style="{ float: 'right' }" icon="el-icon-plus">添加</el-button> 
    </div>
      <hr />
      <!-- 分配-列表 -->
      <el-table
        :data="dataList"
        stripe
        style="width: 100%"
        table-layout="auto"
        class="tableAuto"
      >
      <el-table-column label="#" type="index"></el-table-column>
            <el-table-column :width="flexWidth('SupplierTypeName',dataList,'500')" label="供应商类型" prop="SupplierTypeName"></el-table-column>
            <el-table-column :width="flexWidth('SupplierName',dataList,'')" label="供应商名称" prop="SupplierName"></el-table-column>
            <el-table-column :width="flexWidth('SupplierAddress',dataList,'')" label="地址" prop="SupplierAddress"></el-table-column>
            <el-table-column :width="flexWidth('SupplierContact',dataList,'')" label="联系人" prop="SupplierContact"></el-table-column>
            <el-table-column :width="flexWidth('SupplierPhoneNumber',dataList,'')" label="联系电话" prop="SupplierPhoneNumber"></el-table-column>
            <el-table-column :width="flexWidth('SendStatus',dataList,'')" label="推送状态" prop="SendStatus"></el-table-column>
            <el-table-column :width="flexWidth('SendStatus',dataList,'')" label="投标状态" prop="BidStatus"></el-table-column>
            <el-table-column class="button_column" label="操作" width="350px">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="deleteConfirm(scope.row.id)" v-if="scope.row.LoginName!=='admin'">删除</el-button>
              <el-button type="danger" size="mini" v-else disabled>删除</el-button>
            </template>
          </el-table-column>
      </el-table>
       <!-- 分配-选择添加供应商 -->
        <el-dialog title="添加可投标的供应商" :visible.sync="addDistrDrawerVisible" width="60%" append-to-body :close="addDialogClose" :close-on-click-modal="false">
          <div>
            <el-input v-model="queryInfo.SupplierName" type="text" placeholder="请输入查询关键字"></el-input>
          </div> 
          <div style="margin-top: 10px;">
              
                <el-select v-model="SupplierTypeNewsId" class="form-select" clearable filterable placeholder="请选择供应商类型">
                <el-option v-for="item in bidSelectList" :key="item.id" :label="item.SupplierTypeName" :value="item.NewsId">
                </el-option>
              </el-select>
            
            </div>
            <div style="margin-top: 10px;">
              <el-button class="el-button--primary-Distr" type="primary" @click="onSearch">搜索</el-button>
              <el-button class="el-button--primary-Distr" type="primary" @click="onSubmit">全选</el-button>
            </div>
           <el-table :data="bidList" :key="item" stripe style="width: 100%" table-layout='auto' class="tableAuto">
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column :width="flexWidth('SupplierTypeName',bidList,'')" label="供应商类型" prop="SupplierTypeName"></el-table-column>
                <el-table-column :width="flexWidth('SupplierName',bidList,'')" label="供应商名称" prop="SupplierName"></el-table-column>
                <el-table-column :width="flexWidth('SupplierAddress',bidList,'')" label="地址" prop="SupplierAddress"></el-table-column>
                <el-table-column :width="flexWidth('SupplierContact',bidList,'')" label="联系人" prop="SupplierContact"></el-table-column>
                <el-table-column :width="flexWidth('SupplierPhoneNumber',bidList,'')" label="联系电话" prop="SupplierPhoneNumber"></el-table-column>
               <el-table-column class="button_column" label="操作" width="350px">
                      <template slot-scope="scope">
                        <el-button type="primary" @click="addBidItem(scope.row.id)" size="mini">选择</el-button>
                      </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <el-pagination v-if="!isMobile" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="queryInfo.totalCount">
                </el-pagination>
                <el-pagination v-else @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total,sizes,prev, pager, next" :total="queryInfo.totalCount">
                </el-pagination>
            <span slot="footer" class="dialog-footer">
            <el-button @click="distrDialogClose">取 消</el-button>
            <el-button type="primary" @click="saveAddDistr">确 定</el-button>
            </span>
        </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props:['DemandContent'],
  data() {
    return{
        addDistrDrawerVisible : false,//分配-添加
        queryInfo: {
         title:  '',
		startDate:  '2023-09-09',
		endDate: '2023-10-19',
		supplierNewsId: '',
        status:'',
        userNewsId:'',
        roleName:'',
      },
      dataList:[],
      bidSelectList:[],
      bidList:[],
      SupplierTypeName: '请选择供应商类型',
      SupplierTypeNewsId:'',
      newsid:'',
      queryInfo:{
        totalCount:0,
        pagenum:1,
        pagesize:5,
        SupplierName:''
      }
      
    }
  },
  created(){
    console.log(this.DemandContent)
    this.getDistributeList();
  },
  methods:{
        //分配-添加-取消
      distrDialogClose(){
        this.addDistrDrawerVisible = false;
      },
      query(){},
      async getDistributeList() {
        
        let that = this;
        console.log(that.DemandContent.NewsId)
        let data =  {   
            ProcurementDemandNewsId:that.DemandContent.NewsId
        }
        await that.$http
        .post("ProcuredDemandSupplier/GetListByNoPage",  data)
        .then(
          (res) => {
            //console.log(res);
            if (res.data.Code !== "200")
            return that.$message.error(res.data.Message);
            that.dataList = res.data.Obj;
            that.totalRecordCount = res.data.TotalCount;
          },
          (res) => {
            that.$message.error("远程获取数据失败");
          }
        );
    },
    addDistributeVisible(){
        this.addDistrDrawerVisible = true
        this.getDistributeSelect()
        this.getDistributeBidList()
    },
    //添加-select
    async getDistributeSelect() {
      console.log('select')
        let that = this;
        let data =  {   
           SupplierTypeName: '',
        }
        console.log(that.DemandContent.NewsId)
        await that.$http
        .post("SupplierType/GetListByNoPage",  data)
        .then(
          (res) => {
            console.log(res);
            if (res.data.Code !== "200")
              return that.$message.error(res.data.Message);
              console.log(res.data.Obj);
            that.bidSelectList = res.data.Obj;
            console.log(that.dataList);
            that.totalRecordCount = res.data.TotalCount;
          },
          (res) => {
            that.$message.error("远程获取数据失败");
          }
        );
    },
    //添加-List
    async getDistributeBidList() {
        let that = this;
        let data =  {   
            SupplierTypeNewsId: that.SupplierTypeNewsId,
			ProcurementDemandNewsId:that.DemandContent.NewsId,
			pagenum:that.queryInfo.pagenum,
			pagesize:that.queryInfo.pagesize,
			SupplierName:that.queryInfo.SupplierName,
        }
        console.log(that.DemandContent.NewsId)
        await that.$http
        .post("Supplier/GetListBySelect",  data)
        .then(
          (res) => {
            console.log(res);
            if (res.data.Code !== "200")
              return that.$message.error(res.data.Message);
              console.log(res.data.Obj);
            that.bidList = res.data.Obj;
            console.log(that.dataList);
            that.queryInfo.totalCount = res.data.TotalCount;
          },
          (res) => {
            that.$message.error("远程获取数据失败");
          }
        );
    },
    //添加-List-搜索
    onSearch(){
        this.getDistributeBidList()
    },
    //分配-添加-确定
    addBidItem(id){
        this.$confirm("您确实选择此供应商吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.addBidItemExe(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async addBidItemExe(id){
        let that = this;
        var addItem = null;
        for (var i = 0; i < this.bidList.length; i++) {
            if (that.bidList[i].id === id) addItem = that.bidList[i];
        }
        console.log(addItem)
        let data = {
            ProcurementDemandNewsId:that.DemandContent.NewsId,
			SupplierNewsId: addItem.NewsId,
        }
        await that.$http
        .post("ProcuredDemandSupplier/addObj",  data)
        .then(
          (res) => {
            console.log(res);
            if (res.data.Code !== "200")
              return that.$message.error(res.data.Message);
                that.getDistributeList()
                this.addDistrDrawerVisible = false;
          },
          (res) => {
            that.$message.error("远程获取数据失败");
          }
        );
    },
    //删除
    deleteConfirm(id){
        this.$confirm("您确实要删除此供应商吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.deleteExe(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async deleteExe(id) {
      await this.$http.post("ProcuredDemandSupplier/deleteObj", { id: id }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.getDistributeList()
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
   
			
     /* 监听 pagesize 改变事件 */
    handleSizeChange(newsize) {
      this.queryInfo.pagesize = newsize;
      this.getDistributeBidList();
    },
    /* 监听 当前页 改变事件 */
    handleCurrentChange(newpage) {
      this.queryInfo.pagenum = newpage;
      this.getDistributeBidList();
    },
    flexWidth(prop, tableData, title, num = 0) {
            if (tableData.length === 0) {
                //表格没数据不做处理
                return;
            }
            let flexWidth = 0; //初始化表格列宽

            flexWidth = this.$common.flexWidth(prop, tableData, title, num);
            return flexWidth + "px";
            }
        
  }
};
</script>

<style scoped>
.el-input-group {
  width: 94% ;
}
.el-button--primary-Distr{
  margin-left: 10px;
}
</style>