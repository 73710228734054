<template>
  <div>
  
    <el-card>
      <!-- 搜索框区域 -->
      <el-row :gutter="20">
        <el-col :span="15">
          <div>
            <el-input placeholder="请输入查询关键字" v-model="queryInfo.query" clearable @clear="query">
              <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
            </el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-button type="primary" @click="addDialogVisible = true" icon="el-icon-plus">添加</el-button>
          </div>
        </el-col>
      </el-row>
      <el-table :data="dataList" stripe table-layout='auto' class="tableAuto">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column :width="flexWidth('SupplierTypeName',dataList,'',60)" label="供应商类型名称" prop="SupplierTypeName"></el-table-column>
        
        <el-table-column class="button_column" label="操作" width="350px">
          <template slot-scope="scope">
            <el-button type="success" size="mini" @click="showEditDialog(scope.row.id)" v-if="scope.row.LoginName!=='admin'">编辑</el-button>
            <el-button type="success" size="mini" v-else disabled>编辑</el-button>
            <el-button type="danger" size="mini" @click="deleteConfirm(scope.row.id)" v-if="scope.row.LoginName!=='admin'">删除</el-button>
            <el-button type="danger" size="mini" v-else disabled>删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination v-if="!isMobile" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totalRecordCount">
      </el-pagination>
      <el-pagination v-else @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total,sizes,prev, pager, next" :total="totalRecordCount">
      </el-pagination>
<!-- 添加 -->
      <el-dialog title="添加供应商类型名称" :visible.sync="addDialogVisible" width="60%" :close="addDialogClose" :close-on-click-modal="false">
        <el-form :model="addDialogForm" :rules="addDialogFormRules" ref="addDialogFormRef" label-width="100px" class="dialog_form">
          <el-form-item label="供应商类型名称"   prop="SupplierTypeName" label-width="130px">
            <el-input v-model="addDialogForm.SupplierTypeName"  placeholder="请输入供应商类型名称"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 编辑 -->
     <el-dialog title="编辑供应商类型名称" :visible.sync="editDialogVisible" width="60%" :close="editDialogClose" :close-on-click-modal="false">
        <el-form :model="editDialogForm" :rules="addDialogFormRules" ref="editDialogFormRef" label-width="100px" class="dialog_form">
          <el-form-item label="供应商类型名称" prop="SupplierTypeName" label-width="130px">
            <el-input v-model="editDialogForm.SupplierTypeName" placeholder="请输入供应商类型名称"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogClose">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userList: [],
      totalRecordCount: 0,
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10,
        // token: window.sessionStorage.getItem("token"),
        // loginname: window.sessionStorage.getItem("loginname"),
      },
      dataList: [],
      addDialogVisible: false,
      editDialogVisible: false,
      authDialogVisible: false,
      addDialogForm: {
        SupplierTypeName: "",
      },
      editDialogForm: {
       id:"",
        SupplierTypeName:''
         
      },
      companyList: [],
      //表单验证
      addDialogFormRules: {
        SupplierTypeName: [
          { required: true, message: "请输入供应商类型名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getList();
    var isMobileTemp=window.sessionStorage.getItem("isMobile");
    if(isMobileTemp=='true'){
      this.isMobile=true;
    }else{
      this.isMobile=false;
    }
  },
  methods: {
    // 展示列表
    async getList() {
      await this.$http
        .get("SupplierType/GetList", { params: this.queryInfo})
        .then(
          (res) => {
            if (res.data.Code !== "200")
              return this.$message.error(res.data.Message);
              this.dataList = res.data.Obj;
              this.totalRecordCount = res.data.TotalCount;
          },
          (res) => {
              this.$message.error("远程获取数据失败");
          }
        );
    },
   
    change(e) {
      this.$forceUpdate();
    },
    // 搜索
    query() {
      this.getList();
    },
    /* 监听 pagesize 改变事件 */
    handleSizeChange(newsize) {
      this.queryInfo.pagesize = newsize;
      this.getList();
    },
    /* 监听 当前页 改变事件 */
    handleCurrentChange(newpage) {
      this.queryInfo.pagenum = newpage;
      this.getList();
    },
    /* 监听switch用户状态改变事件 */
    async userStateChanged(userinfo) {
      await this.$http
        .post("User/UpdateUserState/", {
          id: userinfo.id,
          enableflag: userinfo.EnableFlag,
        })
        //.put('User/UpdateUserState/${userinfo.id}/${userinfo.EnableFlag}')
        .then(
          (res) => {
            if (res.data.Code !== "200") {
              userinfo.EnableFlag = !userinfo.EnableFlag;
              return this.$message.error(res.data.Message);
            }
            this.$message.success("用户状态更新成功");
          },
          (res) => {
            userinfo.EnableFlag = !userinfo.EnableFlag;
            this.$message.error("远程操作失败或网络问题");
          }
        );
    },
    // 添加取消
    addDialogClose() {
      this.addDialogVisible = false;
      this.$refs.addDialogFormRef.resetFields();
    },
    // 添加弹出框
    saveAdd() {
      this.$refs.addDialogFormRef.validate((valid) => {
        if (!valid) return this.$message.error("关键信息不能为空");
        this.submitAdd();
      });
      
     
    },
    async submitAdd() {
      await this.$http.post("SupplierType/AddObj", this.addDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.addDialogFormRef.resetFields();
          this.addDialogVisible = false;
          this.$message.success(res.data.Message);
          this.getList();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    // 编辑取消
    editDialogClose() {
      this.editDialogVisible = false;
      this.$refs.editDialogFormRef.resetFields();
    },
    // 编辑
    showEditDialog(id) {
      var tempObj = null;
      for (var i = 0; i < this.dataList.length; i++) {
        if (this.dataList[i].id === id) tempObj = this.dataList[i];
      }
      this.editDialogForm.id = id;
      this.editDialogForm.SupplierTypeName = tempObj.SupplierTypeName;
      this.editDialogVisible = true;
    },
    saveEdit() {
       this.$refs.editDialogFormRef.validate((valid) => {
        if (!valid) return this.$message.error("关键信息不能为空");
        this.submitEdit();
      });
    },
    async submitEdit() {
      await this.$http.post("SupplierType/EditObj", this.editDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.editDialogFormRef.resetFields();
          this.editDialogVisible = false;
          this.$message.success(res.data.Message);
          this.getList();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    // 删除
    deleteConfirm(id) {
      this.$confirm("您确实要删除此记录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.deleteUser(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 删除请求
    async deleteUser(id) {
      await this.$http.post("SupplierType/DeleteObj/", { id: id }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.getList();
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    
    flexWidth(prop, tableData, title, num = 0) {
      if (tableData.length === 0) {//表格没数据不做处理
        return;
      }
      let flexWidth = 0;//初始化表格列宽
      flexWidth = this.$common.flexWidth(prop, tableData, title, num );
      return flexWidth + 'px';
    },
  },
};
</script>

<style lang="less" scoped>
.el-table .cell {
  white-space: pre-line;
}
</style>