import { render, staticRenderFns } from "./DistributeComponent.vue?vue&type=template&id=9e53f58c&scoped=true&"
import script from "./DistributeComponent.vue?vue&type=script&lang=js&"
export * from "./DistributeComponent.vue?vue&type=script&lang=js&"
import style0 from "./DistributeComponent.vue?vue&type=style&index=0&id=9e53f58c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e53f58c",
  null
  
)

export default component.exports