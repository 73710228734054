function countOccurrencesRegex(str, char) {
	// const regex = new RegExp(char, 'g');
	// const matches = str.match(regex);
	// return matches ? matches.length : 0;
	return Array.from(str).reduce((count, currentChar) => {
		return currentChar === char ? count + 1 : count;
	}, 0);
}

function compareDate(strBeginDate,strEndDate) {
	// var stBegin = strBeginDate;
	// var a = stBegin.split(" ");
	// var b = a[0].split("-");
	// var c = a[1].split(":");
	// var dateBegin = new Date(b[0], b[1], b[2], c[0], c[1], c[2])
	
	// var stEnd = strEndDate;
	// var a = stEnd.split(" ");
	// var b = a[0].split("-");
	// var c = a[1].split(":");
	// var dateEnd = new Date(b[0], b[1], b[2], c[0], c[1], c[2])
	
	
	var date1 = new Date(strBeginDate);
	var date2 = new Date(strEndDate);

	var result = date1 >= date2;   
	
	
	return result;
}
module.exports = {
	countOccurrencesRegex,
	compareDate
}