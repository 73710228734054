<template>
    <div>
      <el-card v-if="roleName === '供应商'">
        <supplierComponent />
      </el-card>
      <el-card v-if="roleName === '采购经理' || roleName === '采购专员'" style="visibility=hidden">
        <!-- 搜索框区域 -->
        <SearchComponent @searchDataList="getMsgSearchObj" v-on:showList='showList' @addDataList='addDataList' />
       <!-- 表格 -->
        <el-table :data="dataList" :width="flexWidth('',dataList,'20')" stripe table-layout='auto' class="tableAuto">
          <el-table-column label="#" type="index"></el-table-column>
          <el-table-column :width="flexWidth('ProcurementDemandTitle',dataList,'20')" label="需求标题" prop="ProcurementDemandTitle"></el-table-column>
          <el-table-column :width="flexWidth('TemplateName',dataList,'20')" label="需求模板" prop="TemplateName"></el-table-column>
          <el-table-column :width="flexWidth('SubscriptionPersonName',dataList,'20')" label="业务人员"   prop="SubscriptionPersonName"></el-table-column>
          <el-table-column :width="flexWidth('ProcurementDemandExpirationDateTime',dataList,'20')" label="截至日期" :formatter="ProcurementDemandExpirationDateTime" prop="ProcurementDemandExpirationDateTime"></el-table-column>
          <el-table-column :width="flexWidth('ProcurementDemandStatus',dataList,'20')" label="采购状态" prop="ProcurementDemandStatus">
            <template slot-scope="scope">
              <span style="color:red">
                {{scope.row.ProcurementDemandStatus }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :width="flexWidth('VerifyResult',dataList,'1000')"  label="审核状态" :prop="VerifyResult == '' ? VerifyResult : VerifyResult">
            <template slot-scope="scope">
              <span style="color:red">
                {{scope.row.VerifyResult == '' ? '未审核' : scope.row.VerifyResult  }}
              </span>
            </template>
          </el-table-column>
          <el-table-column class="button_column" label="操作" width="350px">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="showDetailsDrawer(scope.row.id)">详情</el-button>
              <el-button type="warning" size="mini" @click="showAttDialog(scope.row.id)" v-if="roleName == '采购专员' && scope.row.VerifyResult!='同意'">附件</el-button>
              <el-button type="info" size="mini" @click="showDistrDialog(scope.row)" v-if="roleName == '采购专员' && scope.row.VerifyResult!='同意'">分配</el-button>
              <el-button type="success" size="mini" @click="showEditDialog(scope.row)" v-if="roleName == '采购专员' && scope.row.VerifyResult!='同意'">编辑</el-button>
              <el-button type="danger" size="mini" @click="deleteConfirm(scope.row.id)" v-if="roleName == '采购专员' && scope.row.VerifyResult!='同意'">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 详情 -->
        <el-drawer title="详情" :visible.sync="detailsDrawerVisible" size='40%'>
            <DetailsComponent v-if="detailsDestroy" v-on:renovateGetList="renovateGetList" :DemandContent="DemandContent" />
        </el-drawer>
          
        <!-- 附件 -->
        <el-dialog title="附件" :visible.sync="attDialogVisible">
            <UploadComponent :Id=Id v-on:closeFunc="closePicture" />
        </el-dialog>
        <!-- 分配 -->
        <el-drawer title="分配"  :visible.sync="distrDrawerVisible" :direction="direction" size='60%'>
           <DistributeComponent v-if="distrDestroy"  :DemandContent="DemandContent"/>
        </el-drawer>
       
        <!-- 编辑 -->
        <el-dialog title="编辑采购需求" :visible.sync="editDialogVisible" width="60%" :close="editDialogClose" :close-on-click-modal="false">
          <!-- <editComponent v-if="editDestroy"   v-on:closeEdit="closeEdit" ref="editChild"  :editObj="editObj" /> -->
            <el-form :model="editDialogForm" :rules="addDialogFormRules" type="data" ref="editDemandDialogForm" label-width="100px" class="dialog_form">
                 <el-form-item label="需求模板" prop="TemplateName"  required="true" >
                    <el-input v-model="editDialogForm.TemplateName"  placeholder="请输入供应商编码" :disabled="true" ></el-input>
                </el-form-item>
                <el-form-item label="需求标题" prop="ProcurementDemandTitle"  >
                    <el-input v-model="editDialogForm.ProcurementDemandTitle" placeholder="请输入需求标题"  ></el-input>
                </el-form-item>
                <el-form-item label="需求类型"  prop="selectProcurementDemandType" >
                    <el-select v-model="editDialogForm.ProcurementDemandTypeNewsId" class="form-select" clearable filterable placeholder="请选择类型">
                      <el-option v-for="item in selectProcurementDemandType" :key="item.id" :label="item.ProcurementDemandTypeName" :value="item.NewsId">
                      </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="业务人员" prop="SubscriptionPersonNewsId"  >
                    <el-select v-model="editDialogForm.SubscriptionPersonNewsId" class="form-select" clearable filterable placeholder="请选择业务人员">
                    <el-option v-for="item in selectSubscriptionPersonName" :key="item.id" :label="item.SubscriptionPersonName" :value="item.NewsId">
                    </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="截至日期" prop="ProcurementDemandExpirationDateTime"  >
                  <el-date-picker style="width:100%;" v-model="editDialogForm.ProcurementDemandExpirationDateTime" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                    <!-- <el-date-picker style="width:100%;" v-model="editDialogForm.ProcurementDemandExpirationDateTime" type="date" value-format="yyyy-MM-dd" @change="getList"  @input="$forceUpdate()" placeholder="选择日期"></el-date-picker> -->
                </el-form-item>
                <el-form-item label="描述说明">
                    <el-input v-model="editDialogForm.ProcurementDemandDesc" type="textarea" ></el-input>
                </el-form-item>
          </el-form>
                <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogClose">取 消</el-button>
                <el-button type="primary" @click="saveEdit">确 定</el-button>
            </span>
        </el-dialog>
      </el-card>
    </div>
  </template>
  
<script>
import SearchComponent from './SearchComponent.vue';
import DetailsComponent from './DetailsComponent.vue';
import DistributeComponent from '@/views/DemandPublish/DistributeComponent';
import UploadComponent from './UploadComponent.vue';
import supplierComponent from './supplierComponent.vue';
export default {
  components:{
    SearchComponent,
    DetailsComponent,
    DistributeComponent,
    UploadComponent,
    supplierComponent,
    supplierComponent,
  },
  data() {
    return {
      userNewsId: '',
			wxOpenId: '',
			roleName:'',
			supplierNewsId:'',
      dataList: [],
      
      //搜索
       formInline: {
          user: '',
          region: ''
        },
      queryInfo: {
         title:  '',
					startDate:  '2023-09-09',
					endDate: '2023-10-19',
					supplierNewsId: '',
        status:'',
        userNewsId:'',
        roleName:'',
      },
      dataList: [],
      addDialogVisible: false, //添加
      editDialogVisible: false,
      authDialogVisible: false,
      detailsDrawerVisible: false, //详情
      detailsDestroy:false,
      attDialogVisible: false, //附件
      distrDrawerVisible: false, //分配
      distrDestroy:false,//分配
      editDestroy:false,//分配
      addDialogForm: {
        ProcurementDemandTypeName: "",
      },
      //详情-附件-图片
      fileUrl:'',
      attachFileDir:'',
      
      //编辑业务人员
      selectSubscriptionPersonName:[],
      selectProcurementDemandType:[],
      companyList: [],
      DemandContent:'',

       editDialogForm: {
           TemplateName:'',
           ProcurementDemandTitle:'',
           ProcurementDemandTypeName:'',
           SubscriptionPersonNewsId:'',
           ProcurementDemandTypeNewsId:'',
           SubscriptionPersonName:'',
           ProcurementDemandExpirationDateTime:'',
           ProcurementDemandDesc:'',
           TemplateId:''
      }, //编辑框
      selectSubscriptionPersonName:[],
      selectProcurementDemandType:[],
      //表单验证
      addDialogFormRules: {
         procurementDemandTitle: [
          { required: true, message: '请输入需求标题', trigger: 'blur' },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
          ProcurementDemandTypeNewsId: [
          { required: true, message: '请选择需求类型', trigger: 'change' }
        ],
        SubscriptionPersonNewsId: [
          { required: true, message: '请选择业务人员', trigger: 'change' }
        ],
        ProcurementDemandExpirationDateTime: [
                { required: true, message: "请选择截至日期时间", trigger: "blur" },
        ],

        selectTemplateId: [
          { required: true, message: '请选择供应商类型名称', trigger: 'change' }
        ],
        selectProcurementDemandTypeNewsId: [
          { required: true, message: '请选择需求类型', trigger: 'change' }
        ],
        selectSubscriptionPersonNewsId: [
          { required: true, message: '请选择需求类型', trigger: 'change' }
        ],
        ProcurementDemandTitle: [
          { required: true, message: '请输入需求标题', trigger: 'blur' },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        selectDate: [
          { required: true, message: "请选择截至日期", trigger: "blur" },
            {
              min: 1,
              max: 300,
              message: "长度在 1 到 300 个字符",
              trigger: "blur",
            },
        ],
         procurementDemandTitle: [
          { required: true, message: '请输入需求标题', trigger: 'blur' },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
         selectProcurementDemandTypeNewsId: [
          { required: true, message: '请选择需求标题', trigger: 'change' }
        ],
        selectSubscriptionPersonNewsId: [
          { required: true, message: '请选择业务人员', trigger: 'change' }
        ],
        ProcurementDemandExpirationDateTime: [
          { type: 'date', required: true, message: '请选择截至日期', trigger: 'change' }
        ],
        selectStartDate:'',
        
      },
    };
  },
  created() {
    var isMobileTemp = window.sessionStorage.getItem("isMobile");
    if (isMobileTemp == "true") {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
			this.userNewsId =  window.sessionStorage.getItem("userNewsId");
			this.supplierNewsId =  window.sessionStorage.getItem("supplierNewsId");
			this.wxOpenId =  window.sessionStorage.getItem("wxOpenId");
			this.roleName =  window.sessionStorage.getItem("roleName");
      this.rootUrl =  window.sessionStorage.getItem("rootUrl");

      if(this.roleName != '供应商'){
        this.getSubscriptionPersonList();
      this.getTemplateList();
      }
    
      this.getTestTime();
      this.getList();

  },
  methods: {
    showList(){
      this.getList()
    },
    //搜索
     onSubmit() {
        console.log(window.sessionStorage.getItem("token"));
        console.log(window.sessionStorage.getItem("phone"),);
      },
    // 展示列表
    async getList() {
      let that = this;
      let url = '';
       let queryInfo = {};
        url = 'ProcurementDemand/GetListByKey';
        queryInfo = {
            title:'',
						status: '全部',
						startDate: that.selectStartDate[0],
						endDate: that.selectStartDate[1],
						userNewsId: that.userNewsId,
						roleName:that.roleName,
						supplierNewsId:that.supplierNewsId,
        }
      await that.$http
        .get(url,{params: queryInfo})
        .then(
          (res) => {
            console.log(res);
            if (res.data.Code !== "200")
              return that.$message.error(res.data.Message);
           
              that.dataList.length=0;
              res.data.Obj.forEach(element => {
                var obj=element;
                const date1=new Date();
                const date2=new Date(obj.ProcurementDemandExpirationDateTime);
                if(date1<date2 || obj.ProcurementDemandStatus=='已完成' || obj.ProcurementDemandStatus=='已作废'){
                    
                }else{
                  
                    obj.ProcurementDemandStatus='已截止';
                }
                that.dataList.push(obj);
              });
          },
          (res) => {
            that.$message.error("远程获取数据失败");
          }
        );
    },

    change(e) {
      this.$forceUpdate();
    },
    // 取消供应商添加
    addDialogClose() {
      this.addDialogVisible = false;
      if (this.$refs.addDialogFormRef) {
        this.$refs.addDialogFormRef.resetFields();
      }
    },
    //搜索
    getMsgSearchObj(data){
      this.dataList = data
    },
    //搜索添加
    addDataList(){
      this.getList();
    },
    
    //详情
    showDetailsDrawer(id) {
      var tempObj = null;
      console.log(id)
       for (var i = 0; i < this.dataList.length; i++) {
        if (this.dataList[i].id === id) tempObj = this.dataList[i];
      }
      console.log(tempObj)
      this.DemandContent= tempObj
      this.detailsDrawerVisible = true;
      this.detailsDestroy = true;
    },
    //附件
    showAttDialog(id){
      this.Id = id
      this.attDialogVisible = true;
    },
      //分配
      showDistrDialog(row){
      this.DemandContent= row;
        this.distrDrawerVisible = true;
        this.distrDestroy = true;
      },
      editDialogClose() {
      this.editDialogVisible = false;
      //this.$refs.editDemandDialogForm.resetFields();
    },
    // 编辑-关闭弹窗
    closeEdit(){
       this.editDialogVisible = false;;
       this.getList();
    },
    //编辑-打开弹出框
    showEditDialog(row) {
      console.log(1111)
      this.editDialogVisible = true;
      this.editDialogForm.id =  row.id;
      this.editDialogForm.TemplateName = row.TemplateName;
      this.editDialogForm.ProcurementDemandTitle = row.ProcurementDemandTitle;
      this.editDialogForm.TemplateId = row.TemplateId;
      this.editDialogForm.ProcurementDemandTypeName = row.ProcurementDemandTypeName;
      this.editDialogForm.SubscriptionPersonName = row.SubscriptionPersonName;
      this.editDialogForm.ProcurementDemandDesc = row.ProcurementDemandDesc;
      this.editDialogForm.SubscriptionPersonNewsId = row.SubscriptionPersonNewsId;
      this.editDialogForm.ProcurementDemandTypeNewsId = row.ProcurementDemandTypeNewsId;
      this.editDialogForm.ProcurementDemandExpirationDateTime  = new Date(row.ProcurementDemandExpirationDateTime) 
      
    },
   
    dateFormat(fmt, date) {
        var date1 = new Date(date);
          let ret;
          const opt = {
              "Y+": date1.getFullYear().toString(),        // 年
              "m+": (date1.getMonth() + 1).toString(),    // 月
              "d+": date1.getDate().toString(),            // 日
              "H+": date1.getHours().toString(),          // 时
              "M+": date1.getMinutes().toString(),        // 分
              "S+": date1.getSeconds().toString()          // 秒
              // 有其他格式化字符需求可以继续添加，必须转化成字符串
          };
          for (let k in opt) {
              ret = new RegExp("(" + k + ")").exec(fmt);
              if (ret) {
                  fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
              };
          };
          console.log(fmt)
          this.editDialogForm.ProcurementDemandExpirationDateTime = fmt
          return fmt;

      },
     saveEdit() {
      this.$refs.editDemandDialogForm.validate((valid) => {
        if (!valid) return this.$message.error("关键信息不能为空");
      
        this.submitEdit();
      });
    },
    async submitEdit() {
      let transTimeDate = this.editDialogForm.ProcurementDemandExpirationDateTime
       this.dateFormat( 'YYYY-mm-dd HH:MM:SS',transTimeDate)
      let that = this;
      console.log(that.editDialogForm)
      await this.$http
        .post("ProcurementDemand/EditObj", that.editDialogForm)
        .then(
          (res) => {
            if (res.data.Code !== "200") {
              return this.$message.error(res.data.Message);
            }
            this.getList();
            this.editDialogVisible = false;
            this.$message.success(res.data.Message);
          },
          (res) => {
            this.$message.error("远程操作失败或网络问题");
          }
        );
    },
    //获取需求业务人员
    async getSubscriptionPersonList() {
				let that = this;
        let query = { IsEnabled : true};
				await that.$http
          .post('SubscriptionPerson/GetListByNoPage', query)
					.then(
          (res) => {
            console.log(res);
            if (res.data.Code !== "200")
              return that.$message.error(res.data.Message);
            that.selectSubscriptionPersonName = res.data.Obj;
            console.log(that.selectSubscriptionPersonName);
          },
          (res) => {
            that.$message.error("远程获取数据失败");
          }
        );
			
			},
      //获取需求类型列表
			async getTemplateList() {
				let that = this;
				await that.$http
          .post('ProcurementDemandType/GetListByNoPage',{params: that.queryInfo})
					.then(
          (res) => {
            //console.log(res);
            if (res.data.Code !== "200")
              return that.$message.error(res.data.Message);
              
            that.selectProcurementDemandType = res.data.Obj;
            console.log(that.selectProcurementDemandType);
          },
          (res) => {
            that.$message.error("远程获取数据失败");
          }
        );

			},
    deleteConfirm(id) {
      this.$confirm("您确实要删除此记录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.deleteObj(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async deleteObj(id) {
      console.log(id)
      await this.$http
        .post("ProcurementDemand/deleteObj", { id: id })
        .then(
          (res) => {
            if (res.data.Code !== "200") {
              return this.$message.error(res.data.Message);
            }
            this.$message.success(res.data.Message);
            console.log(1)
            this.getList();
          },
          (res) => {
            this.$message.error("远程操作失败或网络问题");
          }
        );
    },
    flexWidth(prop, tableData, title, num = 0) {
      if (tableData.length === 0) {
        //表格没数据不做处理
        return;
      }
      let flexWidth = 0; //初始化表格列宽

      flexWidth = this.$common.flexWidth(prop, tableData, title, num);
      return flexWidth + "px";
    },
    detailsDestroyDom: function() {
      console.log('已销毁')
        this.detailsDestroy = false
        this.$nextTick(() => {
          this.hackReset = true;//重建组件
          console.log('重建组件')
        });
    },
    distrDestroyDom: function() {
      console.log('已销毁')
        this.detailsDestroy = false
        this.$nextTick(() => {
          this.hackReset = true;//重建组件
          console.log('重建组件')
        });
    },
    renovateGetList(){
      this.getList()
    },
    //关闭图片
    closePicture(){
      this.attDialogVisible = false;
    },
      // 默认 当日
      getTestTime () {
        //console.log('111')
        let isDate = new Date()
        let sTime = `${isDate.getFullYear()}-${isDate.getMonth() }-${isDate.getDate()}`
        let sTimes = `${isDate.getFullYear()}-${isDate.getMonth()+1 }-${isDate.getDate()}`
        let eTime = `${isDate.getFullYear()}-${isDate.getMonth() + 1}-${isDate.getDate()}`
        sTime = `${sTime} 00:00:00`
        eTime = `${eTime} 23:59:59`
        this.selectStartDate = [new Date(sTime), new Date(eTime)] // 显示的默认时间
        this.editDialogForm.selectDate = new Date(sTimes)
      },
  },
  watch:{
    'detailsDrawerVisible':function(newValue){
      if(newValue == false)
      this.detailsDestroyDom();
    },
    'distrDrawerVisible':function(newValue){
      if(newValue == false)
      this.distrDestroyDom();
    },
  }
};
</script>
  
<style lang="less" scoped>
.el-table .cell {
  white-space: pre-line;
}

.el-button--primary.is-plain{
    margin-left: 10px;
}
.el-drawer__body>* {
    box-sizing: border-box;
    padding: 0 20px 0 20px;
}


</style>