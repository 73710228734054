<template>
  <div>
    <SearchComponent @searchDataList="getMsgSearchObj" v-on:showList='showList'/>
    <el-table :data="dataList" style="width: 100%"> 
      <el-table-column label="#" type="index"></el-table-column>
      <el-table-column :width="flexWidth('ProcurementDemandTitle',dataList,'20')" label="需求标题" prop="ProcurementDemandTitle"></el-table-column>   
      <el-table-column :width="flexWidth('TemplateName',dataList,'20')" label="需求模板" prop="TemplateName"></el-table-column>   
      <el-table-column :width="flexWidth('SubscriptionPersonName',dataList,'20')" label="业务人员" prop="SubscriptionPersonName"></el-table-column>   
      <el-table-column :width="flexWidth('ProcurementDemandExpirationDateTime',dataList,'20')" label="截至日期" prop="ProcurementDemandExpirationDateTime"></el-table-column>   
      <el-table-column :width="flexWidth('ProcurementDemandStatus',dataList,'')" label="采购状态" prop="ProcurementDemandStatus">
            <template slot-scope="scope">
              <span style="color:red">
                {{scope.row.ProcurementDemandStatus }}
              </span>
            </template>
          </el-table-column>
    <el-table-column class="button_column" label="操作" width="350px">
      <template slot-scope="scope">
        <el-button type="primary" size="mini" @click="showDetailsDrawer(scope.row)" v-if="scope.row.LoginName!=='admin'">详情</el-button>
        <el-button type="primary" size="mini" v-else disabled>详情</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-drawer title="详情" :visible.sync="detailsDrawerVisible" size='50%'>
    <div class="top">
        <div class="top">
            <span>需求标题：</span>
            <span>{{ itemObj.ProcurementDemandTitle }}</span>
        </div>
        <div class="top">
            <span>需求模板：</span>
            <span>{{ itemObj.TemplateName }}</span>
        </div>
        <div class="top">
            <span>需求类型：</span>
            <span>{{ itemObj.ProcurementDemandTypeName }}</span>
        </div>
        <div class="top">
            <span>业务人员：</span>
            <span>{{ itemObj.SubscriptionPersonName +'-'+ itemObj.PhoneNumber }}</span>
        </div>
        <div class="top">
            <span>截至日期：</span>
            <span>{{ itemObj.ProcurementDemandExpirationDateTime }}</span>
        </div>
        <div class="top">
            <span>描述说明：</span>
            <span>{{ itemObj.ProcurementDemandDesc }}</span>
        </div>
        <div class="top">
            <span>采购状态：</span>
            <span>{{ itemObj.ProcurementDemandStatus }}</span>
        </div>
     <div  v-if="detailedList!=null && TemplateId==1">
        <el-table :data="detailedList" stripe table-layout='auto' class="tableAuto">
          <el-table-column label="#" type="index"></el-table-column>
        <el-table-column :width="flexWidth('ItemName',detailedList,'120')" label="品名" prop="ItemName"></el-table-column>
          <el-table-column :width="flexWidth('ItemSpec',detailedList,'120')" label="规格" prop="ItemSpec"></el-table-column>
          <el-table-column :width="flexWidth('ItemUnit',detailedList,'120')" label="单位" prop="ItemUnit"></el-table-column>
            <el-table-column :width="flexWidth('ItemCount',detailedList,'120')" label="数量" prop="ItemCount"></el-table-column>
            <el-table-column :width="flexWidth('SupplierItemPrice',detailedList,'120')" label="单价" prop="SupplierItemPrice"></el-table-column>
            <el-table-column :width="flexWidth('SupplierItemTotalPrice',detailedList,'120')" label="总价" prop="SupplierItemTotalPrice"></el-table-column>
            <el-table-column class="button_column" label="操作" width="350px">
              <template slot-scope="scope">
                <el-button type="success" v-if="itemObj.ProcurementDemandStatus=='已发布'" size="mini" @click="insuredForm(scope.row)">报价</el-button>
              </template>
            </el-table-column>
        </el-table>
    </div>
    <div  v-if="detailedList!=null && TemplateId==2">
        <el-table :data="detailedList" stripe table-layout='auto' class="tableAuto">
          <el-table-column label="#" type="index"></el-table-column>
          <el-table-column :width="flexWidth('DeliveryName',detailedList,'120')" label="起始地" prop="DeliveryName">
             <template slot-scope="scope">
                {{ scope.row.DeliveryName + '-' +  scope.row.DeliveryAddress}}
            </template>
          </el-table-column>
          <el-table-column :width="flexWidth('ConsigneeName-ConsigneeAddress',detailedList,'120')" label="目的地" prop="ConsigneeName-ConsigneeAddress">
            <template slot-scope="scope">
                {{ scope.row.ConsigneeName + '-' +  scope.row.ConsigneeAddress}}
            </template>
          </el-table-column>
          <el-table-column :width="flexWidth('ConsigneeContact-DeliveryContact',detailedList,'120')" label="联系方式" prop="ConsigneeContact-DeliveryContact">
            <template slot-scope="scope">
                {{ scope.row.ConsigneeContact + '-' +  scope.row.DeliveryContact}}
            </template>
          </el-table-column>
            <el-table-column :width="flexWidth('SendUnit',detailedList,'120')" label="单位" prop="SendUnit"></el-table-column>
            <el-table-column :width="flexWidth('SendCount',detailedList,'120')" label="数量" prop="SendCount"></el-table-column>
            <el-table-column :width="flexWidth('SupplierItemPrice',detailedList,'120')" label="单价" prop="SupplierItemPrice"></el-table-column>
            <el-table-column :width="flexWidth('SupplierItemTotalPrice',detailedList,'120')" label="总价" prop="SupplierItemTotalPrice"></el-table-column>
            <el-table-column class="button_column" label="操作" width="350px">
              <template slot-scope="scope">
                <el-button type="success" v-if="itemObj.ProcurementDemandStatus=='已发布'" size="mini" @click="insuredForm(scope.row)">报价</el-button>
              </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="demo-drawer__footer">
            <el-button type="primary" plain @click="showFile" icon="el-icon-picture-outline">附 件</el-button>
        </div>
        </div>
    </el-drawer>
  <!-- 供应商详情 -->
  <el-dialog title="详情" :visible.sync="insuredDrawerVisible">
    <div v-if="TemplateId==1">
        <el-form :model="editDialogForms" :rules="addDialogFormRules" ref="editDialogForms" label-width="100px" class="dialog_form">
        <el-form-item label="品名" prop="ItemName"  >
            <el-input v-model="editDialogForms.ItemName"  placeholder="请输入供应商编码" :disabled="true" ></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="ItemCount"  >
            <el-input v-model="editDialogForms.ItemCount" placeholder="请输入需求标题" :disabled="true" ></el-input>
        </el-form-item>
        <el-form-item label="价格"  prop="SupplierItemPrice">
            <el-input type="number" v-model="editDialogForms.SupplierItemPrice" placeholder="请输入价格（含税含运费 单位：元）" ></el-input>
        </el-form-item>
        <el-form-item label="交付日期" prop="DeliveryDate"  >
        <el-date-picker style="width:100%;" v-model="editDialogForms.DeliveryDate"  placeholder="选择日期"></el-date-picker>
        </el-form-item>
        </el-form>
    </div>
    <div v-if="TemplateId==2">
        <el-form :model="editDialogForm" :rules="addDialogFormRules" ref="editDialogForm" label-width="100px" class="dialog_form">
        <el-form-item label="起始地" prop="DeliveryName"  >
            <el-input v-model="editDialogForm.DeliveryName"  placeholder="请输入供应商编码" :disabled="true" ></el-input>
        </el-form-item>
        <el-form-item label="目的地" prop="ConsigneeName"  >
            <el-input v-model="editDialogForm.ConsigneeName" placeholder="请输入需求标题" :disabled="true" ></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="SendCount"  >
            <el-input v-model="editDialogForm.SendCount" placeholder="请输入需求标题" :disabled="true" ></el-input>
        </el-form-item>
         <el-form-item label="价格" prop="SupplierItemPrice" >
            <el-input type="number" v-model="editDialogForm.SupplierItemPrice" placeholder="请输入价格（含税含运费 单位：元）" ></el-input>
        </el-form-item>
        </el-form>
    </div>
    
    <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogClose">取 消</el-button>
         <el-button type="primary" @click="saveAdd">确 定</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
import SearchComponent from './SearchComponent.vue';
  export default {
    name:'',
    components:{SearchComponent},
     data() {
      return {
        detailsDrawerVisible:false,
        insuredDrawerVisible:false,
        editDialogForm: {
          DeliveryName:'',
          SupplierItemPrice:0 ,
          itemPrice:0,
          ProcurementDemandTypeName:'',
          selectSubscriptionPersonNewsId:'',
          selectProcurementDemandTypeNewsId:'',
          SubscriptionPersonName:'',
          selectDate:'',
          ProcurementDemandDesc:'',
          ConsigneeName:'',
          SendCount:0
        },
        editDialogForms: {
          ItemName:'',
          ItemCount:'',
          SupplierItemPrice:0,
          DeliveryDate:'',
        }, 
        obj: null,
        dataList:[],
       itemObj:{},
       detailedList:[],
       detailedLists:[],
        userNewsId:'',
        supplierNewsId:'',
        wxOpenId:'',
        roleName:'',
        queryInfo: {
            title:  '',
            startDate:  '2023-09-09',
            endDate: '2023-10-19',
            supplierNewsId: '',
            status:'',
            userNewsId:'',
            roleName:'',
        },
        tempSupplierNewsId:"0",
        TemplateId:1,
        fileUrl:'',
        attachFileDir:'',
        rootUrl:'',
        //表单验证
            addDialogFormRules: {
                SupplierItemPrice: [
                  { required: true, message: "请输入价格", trigger: "blur" },
                ],
                  DeliveryDate: [
                    { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
                 ],
                 
            },
            selectStartDate:'',
      }
    },
    created(){
        this.userNewsId =  window.sessionStorage.getItem("userNewsId");
        this.supplierNewsId =  window.sessionStorage.getItem("supplierNewsId");
        this.wxOpenId =  window.sessionStorage.getItem("wxOpenId");
        this.roleName =  window.sessionStorage.getItem("roleName");
        this.rootUrl =  window.sessionStorage.getItem("rootUrl");
        this.getTestTime();
        this.getList();
    },
    methods:{
      showList(){
      this.getList()
    },
        //搜索
        getMsgSearchObj(data){
          this.dataList = data
        },
         // 展示列表
        async getList() {
          console.log(this.supplierNewsId)
          let that = this;
         
          let url = '';
       let queryInfo = {};
        url = 'ProcuredDemandSupplier/GetListByKey';
        queryInfo = {
            title:'',
						status: '全部',
						startDate: that.selectStartDate[0],
						endDate: that.selectStartDate[1],
						userNewsId: that.userNewsId,
						roleName:that.roleName,
						supplierNewsId:that.supplierNewsId,
        }
          await that.$http
              .get(url,{params: queryInfo})
              .then(
              (res) => {
                  console.log(res);
                  if (res.data.Code !== "200")
                  return that.$message.error(res.data.Message);
                  that.dataList.length=0;
              res.data.Obj.forEach(element => {
                var obj=element;
                const date1=new Date();
                const date2=new Date(obj.ProcurementDemandExpirationDateTime);
                if(date1<date2 || obj.ProcurementDemandStatus=='已完成' || obj.ProcurementDemandStatus=='已作废'){
                    
                }else{
                  
                    obj.ProcurementDemandStatus='已截止';
                }
                that.dataList.push(obj);
              });
              },
              (res) => {
                  that.$message.error("远程获取数据失败");
              }
              );
        },
        //显示附件
        showFile(){
           if(this.attachFileDir!=null && this.attachFileDir!=""){
                    this.fileUrl = this.rootUrl+this.attachFileDir;
             window.open(this.fileUrl);
           }
           else{
            this.$message.error("没有附件");
           }
        },
      //详情
      showDetailsDrawer(row) {
        this.detailedList = null;
        this.TemplateId = row.TemplateId;
        this.itemObj=row;
        this.detailsDrawerVisible = true;
        this.attachFileDir=row.ProcurementDemandOtherFileDir;

        this.getDetailed(row)
      },
      //获取-详情-明细
      async getDetailed(row) {
        let that = this;
        let url =''
        if(row.TemplateId==1){
					url= 'ItemDetail/GetListByNoPage'
				}
				if(row.TemplateId==2){
					url= 'ConsigneeDetail/GetListByNoPage'
				}
        let tempParam={
                ProcurementDemandNewsId: row.ProcurementDemandNewsId,
                RoleName: that.roleName,
                SupplierNewsId: that.supplierNewsId,
            }
        await that.$http
            .post(url, tempParam)
            .then(
            (res) => {
                console.log(res.data.Obj);
                if (res.data.Code !== "200")
                return that.$message.error(res.data.Message);
              
                that.detailedList = res.data.Obj;
              
              
            },
            (res) => {
                that.$message.error("远程获取数据失败");
            }
            );
        },
      //取消
      addDialogClose(){
        this.$refs.editDialogForm.resetFields();
         this.insuredDrawerVisible = false
      },
      //确定
      saveAdd(){
         this.$refs.editDialogForms.validate((valid) => {
        if (!valid) return this.$message.error("关键信息不能为空");
        if(this.editDialogForms.SupplierItemPrice=='0'){
          return this.$message.error("价格不能为零");
        }
          this.submitEdit();
        });
      },
      //详情-报价
      insuredForm(row){
        console.log(row)
        this.getCurObj(row.id);
        if(this.TemplateId==1){
          this.editDialogForms.ItemName = row.ItemName;
          this.editDialogForms.ItemCount = row.ItemCount;
          if(row.SupplierItemPrice!=null && row.SupplierItemPrice!=""){
          this.editDialogForms.SupplierItemPrice = row.SupplierItemPrice;
          }
          this.editDialogForms.DeliveryDate = row.DeliveryDate;
				}
				if(this.TemplateId==2){
					this.editDialogForm.DeliveryName = row.DeliveryName+ '-' + row.DeliveryAddress;
          this.editDialogForm.ConsigneeName = row.ConsigneeName + '-' +  row.ConsigneeAddress;
          this.editDialogForm.SendCount = row.SendCount;
          this.editDialogForm.SupplierItemPrice = row.SupplierItemPrice;
				}
        this.insuredDrawerVisible = true;
        
      },
        async getCurObj(id) {
          console.log(id)
        let that = this;
        let data={
          id:id
        }
        let url='';
        if(that.TemplateId==1){
          url='ItemDetail/getObj';
        }
                if(that.TemplateId==2){
          url='ConsigneeDetail/getObj';
        }
        await that.$http
            .post(url, data)
            .then(
            (res) => {
                console.log(res);
                if (res.data.Code !== "200")
                return that.$message.error(res.data.Message);
                console.log(res.data.Obj);
                that.obj = res.data.Obj;
               
            },
            (res) => {
                that.$message.error("远程获取数据失败");
            }
            );
        },
      async submitEdit() {
        let that = this;
        console.log(that.obj)
        let data={};
        let url="";
        if(that.TemplateId==1){
        data = {
             ItemNewsId: that.obj.ItemNewsId,
						ItemDetailNewsId: that.obj.NewsId,
						ProcurementDemandNewsId: that.obj.ProcurementDemandNewsId,
						ItemCount: that.obj.ItemCount,
						ItemPrice: that.editDialogForms.SupplierItemPrice,
						DeliveryDate: that.editDialogForms.DeliveryDate,
						AddUserNewsId: that.userNewsId,
						SupplierNewsId:that.supplierNewsId,
            };
            url="ItemDetailSupplier/AddOrEditObj";
        }
         if(that.TemplateId==2){
          data = {
                ConsigneeNewsId: that.obj.ConsigneeNewsId,
                SendAddressNewsId:that.obj.SendAddressNewsId,
                ConsigneeDetailNewsId: that.obj.NewsId,
                ProcurementDemandNewsId: that.obj.ProcurementDemandNewsId,
                SendCount: that.obj.SendCount,
                SendPrice: that.editDialogForm.SupplierItemPrice,
                SendUnit:that.obj.SendUnit,
                AddUserNewsId: that.userNewsId,
                SupplierNewsId:that.supplierNewsId,
            };
            url="ConsigneeDetailSupplier/AddOrEditObj";
        }
        
            console.log(data)
        await that.$http
            .post(url, data)
            .then(
            (res) => {
                console.log(res);
                if (res.data.Code !== "200")
                return that.$message.error(res.data.Message);
                console.log(res.data.Obj);
                that.insuredDrawerVisible = false;
                that.getDetailed(that.itemObj);
            },
            (res) => {
                that.$message.error("远程获取数据失败");
            }
            );
        },
      getTestTime () {
        let isDate = new Date()
        let sTime = `${isDate.getFullYear()}-${isDate.getMonth() + 1}-${isDate.getDate()}`
        let eTime = `${isDate.getFullYear()}-${isDate.getMonth() + 1}-${isDate.getDate()}`

        sTime = `${sTime} 00:00:00`
        eTime = `${eTime} 23:59:59`
        this.selectStartDate = [new Date(sTime), new Date(eTime)] // 显示的默认时间
        this.editDialogForm.selectDate = new Date(sTime)
        // this.filters.orderTimeStart = sTime  //查询 赋值
        // this.filters.orderTimeEnd = eTime
      },
      flexWidth(prop, tableData, title, num = 0) {
        if (tableData.length != null && tableData.length === 0) {
          //表格没数据不做处理
          return;
        }
        let flexWidth = 0; //初始化表格列宽

        flexWidth = this.$common.flexWidth(prop, tableData, title, num);
        return flexWidth + "px";
      },
    }
  }
</script>

<style scoped>
  .top {
    padding: 5px 0 5px 0;
    margin: 0 10px 0 10px;
    font-size: 14px;
}
.demo-drawer__footer{
     position: fixed;
     bottom: 20px;

}
</style>