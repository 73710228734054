<template>
  <div>
     <el-upload class="upload-demo"  action="https://jsonplaceholder.typicode.com/posts/" 
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :before-upload="beforeAvatarUpload"
         multiple
        :limit="3"
        accept=".jpg,.png,.pdf"
        :on-exceed="handleExceed"
        :file-list="fileList">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">只能上传jpg/png/Pdf文件，且不超过2M</div>
    </el-upload>
  </div>
</template>

<script>
  export default {
    name:'',
    props:['Id'],
    data () {
        return{
            //上传附件
            fileList: {},
            Id:'',
        }
   },
   methods:{
    //上传图片PNG
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log('你好');
      },
      handleExceed(files, fileList) {
        console.log('你好');
        this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      beforeAvatarUpload(file) {
        console.log(1)
        const isJPG = file.type === 'image/jpeg';
        const isPDF = file.type === 'application/pdf';
        const isPNG = file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG && !isPDF && !isPNG) {
          this.$message.error('上传附件只能是 JPG 或 PNG 或 PDF格式!');
          return;
        }
        if (!isLt2M) {
          this.$message.error('上传附件大小不能超过 2MB!');
          return;
        }
        this.uploadFile(file)
      },
       async uploadFile(file) {
          let formData = new FormData()
          //let file = item.raw
          //console.log(item)
          formData.append('id', this.Id);
          formData.append('file', file)
          await this.$http.post("ProcurementDemand/UploadAttachFile", formData).then(
            (res) => {
              if (res.data.Code !== "200") {
                return this.$message.error(res.data.Message);
              }
              this.$message.success(res.data.Message);
              this.$emit('closeFunc');
            },
            (res) => {
              this.$message.error("远程操作失败或网络问题");
            }
          );
        },
    }
  }
</script>

<style scoped>
  
</style>