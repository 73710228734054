<template>
  <div>
    <!-- {{ DemandContent }} -->
    <div class="top" v-if="curObj!=null">
        <div class="top">
            <span>需求标题：</span>
            <span>{{ curObj.ProcurementDemandTitle }}</span>
        </div>
        <div class="top">
            <span>需求模板：</span>
            <span>{{ curObj.TemplateName }}</span>
        </div>
        <div class="top">
            <span>需求类型：</span>
            <span>{{ curObj.ProcurementDemandTypeName }}</span>
        </div>
        <div class="top">
            <span>业务人员：</span>
            <span>{{ curObj.SubscriptionPersonName }}</span>
        </div>
        <div class="top">
            <span>截至日期：</span>
            <span>{{ curObj.ProcurementDemandExpirationDateTime }}</span>
        </div>
        <div class="top">
            <span>描述说明：</span>
            <span>{{ curObj.ProcurementDemandDesc }}</span>
        </div>
        <div class="top">
            <span>采购状态：</span>
            <span>{{ pdStatus }}</span>
        </div>
        <div class="top">
            <span>审核状态：</span>
            <span>{{ curObj.VerifyResult == '' ? '未审核' : curObj.VerifyResult}}</span>
        </div>
        <div class="top">
            <span>审核说明：</span>
            <span>{{ curObj.VerifyDesc }}</span>
        </div>
    </div>
    <div>
        <el-table :data="dataList" stripe table-layout='auto' class="tableAuto" v-if="curObj.TemplateId==1">
          <el-table-column label="#" type="index"></el-table-column>
          <el-table-column :width="flexWidth('ItemName',dataList,'')" label="品名" prop="ItemName"></el-table-column>
          <el-table-column :width="flexWidth('ItemSpec',dataList,'')" label="规格" prop="ItemSpec"></el-table-column>
          <el-table-column :width="flexWidth('ItemUnit',dataList,'')" label="单位" prop="ItemUnit"></el-table-column>
          <el-table-column :width="flexWidth('ItemCount',dataList,'')" label="数量" prop="ItemCount"></el-table-column>
          <el-table-column class="button_column" label="操作" width="350px" v-if="roleName === '采购专员' && curObj.VerifyResult != '同意'">
            <template slot-scope="scope">
              <el-button type="success" size="mini" @click="showEditDialog(scope.row.id)" >编辑</el-button>
              
              <el-button type="danger" size="mini" @click="deleteConfirm(scope.row.id)">删除</el-button>
             
            </template>
          </el-table-column>
        </el-table>
          <el-table :data="dataList" stripe table-layout='auto' class="tableAuto" v-if="curObj.TemplateId==2">
          <el-table-column label="#" type="index"></el-table-column>
          <el-table-column :width="flexWidth('DeliveryName',dataList,'')" label="发货单位" prop="DeliveryName"></el-table-column>
          <el-table-column :width="flexWidth('DeliveryAddress',dataList,'')" label="发货地址" prop="DeliveryAddress"></el-table-column>
           <el-table-column :width="flexWidth('ConsigneeName',dataList,'')" label="收货单位" prop="ConsigneeName"></el-table-column>
          <el-table-column :width="flexWidth('ConsigneeAddress',dataList,'')" label="收货地址" prop="ConsigneeAddress"></el-table-column>
          <el-table-column :width="flexWidth('SendUnit',dataList,'')" label="单位" prop="SendUnit"></el-table-column>
          <el-table-column :width="flexWidth('SendCount',dataList,'')" label="数量" prop="SendCount"></el-table-column>
          <el-table-column class="button_column" label="操作" width="350px"  v-if="roleName === '采购专员' && curObj.VerifyResult != '同意'">
            <template slot-scope="scope">
              <el-button type="success" size="mini" @click="showEditDialog(scope.row.id)" >编辑</el-button>
              <el-button type="danger" size="mini" @click="deleteConfirm(scope.row.id)" >删除</el-button>
            
            </template>
          </el-table-column>
        </el-table>
      <!-- 底部 -->
        <div class="demo-drawer__footer">
            <el-button type="primary" plain @click="checkDrawerVisible = true" v-if="roleName === '采购经理' && curObj.VerifyResult!='同意'" icon="el-icon-circle-plus-outline">审 核</el-button>
            <el-button type="primary" plain @click="invalidDrawer" v-if="roleName === '采购经理' && (pdStatus=='已截止' || pdStatus=='已发布')" icon="el-icon-circle-plus-outline">作 废</el-button>
            <el-button type="primary" plain @click="showQuote" v-if="roleName === '采购经理'" :icon="isShowPrice==true?'el-icon-document':'el-icon-document-add'">{{isShowPrice==true?'关闭':'显示'}}</el-button>
            <el-button type="primary" plain @click="examineForm" v-if="curObj.VerifyResult!='同意' && roleName === '采购专员'" icon="el-icon-circle-plus-outline">明 细</el-button>
            <el-button type="primary" plain @click="checkRequest" v-if="curObj.VerifyResult!='同意'  && roleName === '采购专员'" icon="el-icon-edit">申 请</el-button>
            <el-button type="primary" plain @click="insuredForm" icon="el-icon-table-lamp" >报 价</el-button>
            <el-button type="primary" plain @click="viewAttachments" icon="el-icon-picture-outline">附 件</el-button>
        </div>
        </div>
    <!-- 详情-审核 -->
      <el-dialog title="审核采购需求" :visible.sync="checkDrawerVisible" width="60%"  append-to-body :close="addDialogClose" :close-on-click-modal="false">
        <div >
           <CheckComponent v-on:checkFunc="checkDialogClose" :DemandContent="DemandContent" />
        </div>
    </el-dialog>
    <!-- 详情-作废 -->
    <el-dialog title="审核采购需求" :visible.sync="invalidDrawerVisible" width="60%"  append-to-body :close="addDialogClose" :close-on-click-modal="false">
        <div >
           <el-form :model="addDialogForm" :rules="addDialogFormRules" ref="addDialogForm" label-width="100px" class="dialog_form">
            <el-form-item label="作废描述说明" :required="true" label-width="130px">
                <el-input type="textarea" v-model="invalidText" required-asterisk="false" ></el-input>
            </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="invalidDialogClose">取 消</el-button>
            <el-button type="primary" @click="saveAddInvalid">确 定</el-button>
        </span>
    </el-dialog>
    <!-- 详情-明细 -->
    <el-dialog title="品项明细添加" :visible.sync="examineDrawerVisible" width="60%"  append-to-body :close="addDialogClose" :close-on-click-modal="false">
        <div v-if="curObj.TemplateId==1">
            <el-form :model="addDialogForm" :rules="addDialogFormRules" ref="addBrightDialogForm" label-width="100px" class="dialog_form">
                <el-form-item label="品名" prop="ItemNewsId" >
                    <el-select v-model="addDialogForm.ItemNewsId" class="form-select" clearable filterable placeholder="请选择品名">
                    <el-option v-for="item in itemList" :key="item.NewsId" :label="item.ItemName" :value="item.NewsId">
                    </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="数量" prop="ItemCount"    >
                <el-input type="number" v-model="addDialogForm.ItemCount" placeholder="请输入数量"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div v-if="curObj.TemplateId==2">
             <el-form :model="addDialogForms" :rules="addDialogFormRules" ref="addSteamDialogFormRules" label-width="100px" class="dialog_form">
                <el-form-item label="起始地" prop="SendAddressNewsId"    >
                    <el-select v-model="addDialogForms.SendAddressNewsId" class="form-select" clearable filterable placeholder="请选择品名">
                    <el-option v-for="item in deliveryList" :key="item.NewsId" :label="item.DeliveryName + '-' + item.DeliveryAddress" :value="item.NewsId">
                    </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="目的地" prop="ConsigneeNewsId"   >
                    <el-select v-model="addDialogForms.ConsigneeNewsId" class="form-select" clearable filterable placeholder="请选择品名">
                    <el-option v-for="item in consigneeList" :key="item.NewsId" :label="item.ConsigneeName+ '-' +  item.ConsigneeAddress" :value="item.NewsId">
                    </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="单位" prop="SendUnit"     >
                <el-input type="text" v-model="addDialogForms.SendUnit" placeholder="请输入数量"  ></el-input>
                </el-form-item>
                <el-form-item label="数量" prop="SendCount"    >
                <el-input type="number" v-model="addDialogForms.SendCount" placeholder="请输入数量"  ></el-input>
                </el-form-item>
            </el-form>
        </div>
            <span slot="footer" class="dialog-footer">
            <el-button @click="examineDialogClose">取 消</el-button>
            <el-button type="primary" @click="saveAddExamine">确 定</el-button>
            </span>
    </el-dialog>
    <!-- 详情-报价 -->
    <el-dialog title="报价列表" :visible.sync="insuredDrawerVisible" width="60%"  append-to-body :close="addDialogClose" :close-on-click-modal="false">
        <div>    
            <span class="dialog-title">报价列表</span>
            <el-table :data="quoteList">
                <el-table-column label="排序" type="index"></el-table-column>
                <el-table-column :width="flexWidth('SupplierName',quoteList,'')" label="供应商名称" prop="SupplierName"></el-table-column>
                <el-table-column :width="flexWidth('TotalPrice',quoteList,'')" label="报价" prop="TotalPrice"></el-table-column>
                <el-table-column class="button_column" label="操作" >
                   <template slot-scope="scope">
                       <el-button type="primary" size="mini" @click="quoteDetails(scope.row)" >详情</el-button>
                       <el-button type="success" size="mini" @click="winBid(scope.row)" v-if="roleName == '采购经理' && pdStatus=='已截止'"  >中标</el-button>
                   </template>
               </el-table-column>
            </el-table>
        </div> 
        <div v-if="supplierName !== ''">
            <div class="dialog-title dialog-title-two">供应商报价明细</div>
            <span>供应商名称：{{supplierName }}</span>
             <el-table :data="quoteItem">
                <el-table-column label="排序" type="index"></el-table-column>
                <el-table-column :width="flexWidth('ItemName',quoteList,'')" label="品名" prop="ItemName"></el-table-column>
                <el-table-column :width="flexWidth('ItemUnit',quoteList,'')" label="单位" prop="ItemUnit"></el-table-column>
                <el-table-column :width="flexWidth('ItemCount',quoteList,'')" label="数量" prop="ItemCount"></el-table-column>
                <el-table-column :width="flexWidth('SupplierItemTotalPrice',quoteList,'')" label="总计" prop="SupplierItemTotalPrice"></el-table-column>
            </el-table>
        </div>
    </el-dialog>
    <!-- 详情-附件 -->
    <el-dialog title="附件内容" :visible.sync="adnexaDrawerVisible" width="60%"  append-to-body :close="addDialogClose" :close-on-click-modal="false">
            <div>
                  <el-image v-for="url in urls" :key="url" :src="url" lazy></el-image>
            </div>
    </el-dialog>
    <!-- 详情-明细-编辑 -->
    <el-dialog title="明细编辑" :visible.sync="editDialogVisible" width="60%" append-to-body :close="editDialogClose" :close-on-click-modal="false">
        <!-- 供货商 -->
        <div v-if="curObj.TemplateId==1">
        <el-form :model="editDialogForm" :rules="addDialogFormRules" ref="editOftenDialogForm" label-width="100px" class="dialog_form">
            <el-form-item label="品名" prop="ItemName"   >
              <el-input type="text" :disabled="true" :value="ItemName" ></el-input>
          </el-form-item>
             <el-form-item label="数量" prop="ItemCount"   >
              <el-input type="number" v-model="editDialogForm.ItemCount" placeholder="请输入数量"  ></el-input>
            </el-form-item>
            </el-form>
            </div>
          <!-- 汽车 -->
         <div v-if="curObj.TemplateId==2">
            <el-form :model="editDialogForms" :rules="addDialogFormRules" ref="editTransportDialogForm" label-width="100px" class="dialog_form">
                <el-form-item label="起始地" prop="DeliveryName"   >
                <el-input type="text" :disabled="true" :value="editDialogForms.DeliveryName + '-' + editDialogForms.DeliveryAddress" ></el-input>
            </el-form-item>
            <el-form-item label="目的地" prop="DeliveryName"   >
                <el-input type="text" :disabled="true" :value="editDialogForms.DeliveryName +'-'+ editDialogForms.DeliveryAddress" ></el-input>
            </el-form-item>
                <el-form-item label="单位" prop="SendUnit"   >
                <el-input type="digit" v-model="editDialogForms.SendUnit" placeholder="请输入数量"  ></el-input>
                </el-form-item>
                <el-form-item label="数量" prop="SendCount"   >
                <el-input type="number" v-model="editDialogForms.SendCount" placeholder="请输入数量"  ></el-input>
                </el-form-item>
                </el-form>
            </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="editDialogClose">取 消</el-button>
            <el-button type="primary" @click="saveEdit">确 定</el-button>
          </span>
        </el-dialog>
  </div>
</template>

<script>

import CheckComponent from '@/views/DemandPublish/CheckComponent'
	import dataValidate from '@/utils/dataValidate.js'
  export default {
    name:'',
      components:{CheckComponent},
    props:['DemandContent' ],
    data () {
        return{
            examineDrawerVisible: false, //详情-明细
            askVisibleClose:false, //详情-申请
            insuredDrawerVisible:false, //详情-报价
            adnexaDrawerVisible:false, //详情-附件
            editDialogVisible:false, //明细-编辑
            checkDrawerVisible:false,//详情-审核
            invalidDrawerVisible:false,//详情-作废
            pdStatus: '',//详情-作废-以截至-已作废
            isShowPrice: false,//显示
            addDialogForm: {
                ItemNewsId:'',
                ItemCount:0,
                ProcurementDemandNewsId: '',
				UserNewsId:'',
            },
             addDialogForms: {
                SendAddressNewsId:'',
                ConsigneeNewsId:'',
                SendCount:0,
                SendUnit:'',
                ProcurementDemandNewsId: '',
				UserNewsId:'',
            },
            dataList:[],
            quoteList:[],//报价列表
            supplierName:'',//供应行名称
            quoteItem:{},//供应商报价详情
            invalidText:'',
            editDialogForm: {
                id:'',
                ItemCount:0,
            }, //编辑框
            editDialogForms:{
                DeliveryName:'',
                DeliveryAddress:'',
                ConsigneeName:'',
                ConsigneeAddress:'',
                SendUnit:'',
                SendCount:'',
                id:''
            },
            ItemName:'',
            templateId:0,
            roleName:'',
            userNewsId:'',
            curObj:{},
            itemList:[],
            consigneeList:[],
            deliveryList:[],
            isAscOrder: false,
            fileUrl:'',//附件
            attachFileDir:'',//附件

            //表单验证
            addDialogFormRules: {
                ItemNewsId: [
                    { required: true, message: '请选择品名', trigger: 'change' }
                ],
                ItemCounts: [
                    { required: true, message: '请输入需求标题', trigger: 'blur' },
                ],
                //明细-运输
                SendAddressNewsId: [
                { required: true, message: '请选择起始地', trigger: 'blur' },
                ],
                ConsigneeNewsId: [
                    { required: true, message: "请选择目的地", trigger: "blur" },
                ],
                 ItemCount: [
                   { required: true, message: '请输入数量', trigger: 'blur' },
                    
                ],
                SendCount: [
                   { required: true, message: '请输入数量', trigger: 'blur' },
                ],
            },
        }
   },
   created(){
       this.curObj=this.DemandContent;

       this.templateId=this.curObj.TemplateId;
       this.roleName=window.sessionStorage.getItem("roleName");
       this.userNewsId=window.sessionStorage.getItem("userNewsId");
       this.supplierNewsId = window.sessionStorage.getItem("supplierNewsId");
       this.rootUrl =  window.sessionStorage.getItem("rootUrl");
       console.log(this.curObj);
       console.log(this.roleName);
       this.getItemDetailList();
       this.getTestTime();
       this.getObj()
       
   },
    methods:{
           // 默认 当日
      getTestTime () {
        // const timestamp = 1613481600000; // 时间戳，单位为毫秒
        // const date = new Date(timestamp);
        // const year = date.getFullYear();
        // const month = date.getMonth() + 1; // 月份从0开始，需要加1
        // const day = date.getDate();
        // const hour = date.getHours();
        // const minute = date.getMinutes();
        // const second = date.getSeconds();
        // const formattedTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        // if( this.curObj.ProcurementDemandExpirationDateTime > formattedTime){
        //     this.pdStatus = this.curObj.ProcurementDemandStatus;
        // }
        const date1=new Date();
        const date2=new Date(this.curObj.ProcurementDemandExpirationDateTime);
        if(date1<date2 || this.curObj.ProcurementDemandStatus=='已完成' || this.curObj.ProcurementDemandStatus=='已作废'){
            this.pdStatus = this.curObj.ProcurementDemandStatus;
        }else{
           
            this.pdStatus ='已截止';
        }
      },
       
        //详情-明细
        examineForm(){
            let that = this;
            if(that.curObj.TemplateId==1){
                that.getItemList();
            }else if(that.curObj.TemplateId==2){
                that.showConsignee()
                that.showDelivery();
            }
            this.examineDrawerVisible = true;
        },
        //详情-明细-取消
        examineDialogClose(){
            this.examineDrawerVisible = false;
            if(this.curObj.TemplateId == 1){
                  this.$refs.addBrightDialogForm.resetFields();
              }
               if(this.curObj.TemplateId == 2){
                this.$refs.addSteamDialogFormRules.resetFields();
              }
        },
        //详情-明细-确定
        saveAddExamine(){
            let that=this;
            if(that.curObj.TemplateId==1){
                that.$refs.addBrightDialogForm.validate((valid) => {
                if (!valid) return that.$message.error("关键信息不能为空");
                if(that.addDialogForm.ItemCount==0){
                    return that.$message.error("数量不正确");
                }
                 that.saveAddExe()
                })
            }else if(that.curObj.TemplateId==2){
                 that.$refs.addSteamDialogFormRules.validate((valid) => {
                    if (!valid) return that.$message.error("关键信息不能为空");
                    if (!valid) return that.$message.error("关键信息不能为空");
                if(that.addDialogForms.SendCount==0){
                    return that.$message.error("数量不正确");
                }
                    that.saveAddExe()
                    })
            }
        
        },
        showQuote(){
            let contentStr = '';
            if(this.isShowPrice==true){
					contentStr="确实要关闭价格显示授权吗";
				}
				else{
					contentStr="确实要打开价格显示授权吗";
				}
            this.$confirm(contentStr, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                //执行删除方法
                    this.showQuoteRequest();
                })
                .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消操作",
                });
                });
        },
        async showQuoteRequest(){
            let data = {
                id : this.curObj.id
            }
            await this.$http
            .post("ProcurementDemand/changeShowPriceByPD", data)
            .then(
            (res) => {
                if (res.data.Code !== "200") {
                   
                return this.$message.error(res.data.Message);
                }
                this.$message.success(res.data.Message);
                 this.getObj();
            },
            (res) => {
                this.$message.error("远程操作失败或网络问题");
            }
            );
        },
         async saveAddExe() {
            let that = this;
            let url = '';
            let data = null;
            if(that.curObj.TemplateId === 1){
                url = 'ItemDetail/addObj'
                that.addDialogForm.UserNewsId=that.userNewsId;
                that.addDialogForm.ProcurementDemandNewsId=that.curObj.NewsId;
                data = that.addDialogForm
                
            }
             if(that.curObj.TemplateId === 2){
                url = 'ConsigneeDetail/addObj';
                 that.addDialogForms.UserNewsId=that.userNewsId;
                that.addDialogForms.ProcurementDemandNewsId=that.curObj.NewsId;
                data = that.addDialogForms
               
            }
            
            await that.$http
            .post(url, data)
            .then(
            (res) => {
                console.log(res);
                console.log('我是供货商',res);
                if (res.data.Code !== "200")
                return that.$message.error(res.data.Message);
                that.getItemDetailList();
                that.examineDrawerVisible = false;
                  if(that.curObj.TemplateId == 1){
                        this.$refs.addBrightDialogForm.resetFields();
                    }
                    if(that.curObj.TemplateId == 2){
                        this.$refs.addSteamDialogFormRules.resetFields();
                    }
            },
            (res) => {
                that.$message.error("远程获取数据失败");
            }
        )
        },
        //详情-报价
        async insuredForm() {
            this.supplierName = '';
            this.insuredDrawerVisible=true;
            let that = this;
            let data = {
					ProcurementDemandNewsId:that.DemandContent.NewsId,
					TemplateId: that.templateId,
					IsAscOrder: that.isAscOrder,
				};
            
            await that.$http
            .post('ProcuredDemandSupplier/GetQuoteSupplierList', data )
            .then(
            (res) => {
                console.log(res);
                if (res.data.Code !== "200")
                return that.$message.error(res.data.Message);
                // that.dataList = res.data.Obj;
                console.log(res.data.Obj);
                that.quoteList = res.data.Obj
            },
            (res) => {
                that.$message.error("远程获取数据失败");
            }
            )
        },
        //报价-详情
        async quoteDetails(row){
            this.supplierName = row.SupplierName
            let that = this;
            let tempSupplierNewsId="";
				if(that.roleName!='供应商'){
					tempSupplierNewsId="0";
				}else{
					tempSupplierNewsId=that.supplierNewsId;
				}
            this.insuredDrawerVisible=true;
            let data = {
					ProcurementDemandNewsId: row.ProcurementDemandNewsId,
					RoleName: that.roleName,
					SupplierNewsId: tempSupplierNewsId,
				};
            
            await that.$http
            .post('ItemDetail/GetListByNoPage', data )
            .then(
            (res) => {
                console.log(res);
                if (res.data.Code !== "200")
                return that.$message.error(res.data.Message);
                // that.dataList = res.data.Obj;
                console.log(res.data.Obj);
                that.quoteItem = res.data.Obj
            },
            (res) => {
                that.$message.error("远程获取数据失败");
            }
            )
        },
        //报价-中标
        winBid(row){
             console.log(this.roleName)
            this.$confirm("您确实要选择此供应商中标吗, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(() => {
                //执行中标方法
                this.winBidRequest(row);
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消操作",
                });
            });
        },
        
        //详情-审核
        checkDialogClose(){
            this.checkDrawerVisible = false;
            this.getObj();
            this.$emit('renovateGetList')
        },
        //详情-报废
        invalidDrawer(){
            this.invalidDrawerVisible = true
        },
         //详情-报废-取消
        invalidDialogClose(){
            this.invalidDrawerVisible = false
        },
        //详情-报废-确定
        saveAddInvalid(){
            let that=this;
           
             that.$confirm("您确实要作废此记录吗, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                     console.log('111111');
                //执行删除方法
                that.getAccessTokenCancel();
                })
                .catch(() => {
                that.$message({
                    type: "info",
                    message: "已取消操作",
                });
                });
        },
        async getAccessTokenCancel() {
             console.log('3333');
        await this.$http.post("User/getAccessToken").then(
            (res) => {
                 console.log('22222');
                console.log(res);
             if (res.data.Code !== "200")
              return that.$message.error(res.data.Message);
              this.saveAdd(res.data.Obj)
          
          },
          (res) => {
            that.$message.error("远程获取数据失败");
          })
        },
        async saveAdd(accessToken) {
            let that=this;
            let data={
                id:that.curObj.id,
                CancelDesc: that.invalidText,
                AccessToken: accessToken
                            
                }
            await that.$http.post("ProcurementDemand/cancelObj",data).then(
                (res) => {
                if (res.data.Code !== "200")
                return that.$message.error(res.data.Message);
                that.invalidDrawerVisible = false
                that.getObj();
            
            },
            (res) => {
                that.$message.error("远程获取数据失败");
            })
        },
        
        // 中标请求
        async winBidRequest(row) {
        await this.$http.post("Supplier/DeleteObj/", { id: id }).then(
            (res) => {
            if (res.data.Code !== "200") {
                return this.$message.error(res.data.Message);
            }
            this.$message.success(res.data.Message);
            this.getList();
            this.query();
            },
            (res) => {
            this.$message.error("远程操作失败或网络问题");
            }
        );
        },
        async getObj() {
            let that = this;
            await that.$http
            .post('ProcurementDemand/getObj', {NewsId : that.DemandContent.NewsId} )
            .then(
            (res) => {
                console.log(res);
                if (res.data.Code !== "200")
                return that.$message.error(res.data.Message);
                // that.dataList = res.data.Obj;
                that.isShowPrice=res.data.Obj.IsShowPrice;
                console.log(that.dataList);
                that.templateId = res.data.Obj.TemplateId;
                that.curObj = res.data.Obj;
                console.log(that.curObj)
            },
            (res) => {
                that.$message.error("远程获取数据失败");
            }
        )
        },
        // 详情-明细-列表
        async getItemDetailList() {
            console.log(this.DemandContent)
            let that = this;
            let url="";
            if(that.templateId==1){
                url="ItemDetail/GetListByNoPage";
            }
            if(that.templateId==2){
                url="ConsigneeDetail/GetListByNoPage";
            }
            let tempParam={
                ProcurementDemandNewsId: that.DemandContent.NewsId,
				RoleName: that.roleName,
				SupplierNewsId: "0",
            }
            await that.$http
            .post(url, tempParam )
            .then(
            (res) => {
                console.log(res);
                if (res.data.Code !== "200")
                return that.$message.error(res.data.Message);
                // that.dataList = res.data.Obj;
                console.log(that.dataList);
                that.dataList=res.data.Obj;
            },
            (res) => {
                that.$message.error("远程获取数据失败");
            }
        );
			
			
		},
       
        async getItemList() {
        let that = this;
        let tempParam={
            ItemName: '',
			IsEnabled:true,
         }
        await that.$http
        .post('Item/GetListByNoPage', tempParam )
        .then(
            (res) => {
                // console.log(res);
                if (res.data.Code !== "200")
                return that.$message.error(res.data.Message);
                that.itemList = res.data.Obj;
            },
            (res) => {
                that.$message.error("远程获取数据失败");
            }
        );
			
			
		},
        //编辑
        showEditDialog(id) {
            let that = this
            that.editDialogVisible = true
            var tempObj = null;
            for (var i = 0; i < that.dataList.length; i++) {
                if (that.dataList[i].id === id) tempObj = that.dataList[i];
            }
            console.log(tempObj)
            if(that.curObj.TemplateId==1){
                that.editDialogForm.id = tempObj.id
                that.ItemName = tempObj.ItemName
                that.editDialogForm.ItemCount = tempObj.ItemCount
                that.editDialogForm.ItemCount = tempObj.ItemCount
            }else{
                that.editDialogForms.DeliveryName = tempObj.DeliveryName
                that.editDialogForms.DeliveryAddress = tempObj.DeliveryAddress
                that.editDialogForms.ConsigneeName = tempObj.ConsigneeName
                that.editDialogForms.ConsigneeAddress = tempObj.ConsigneeAddress
                that.editDialogForms.SendUnit = tempObj.SendUnit
                that.editDialogForms.SendCount = tempObj.SendCount
                that.editDialogForms.id = tempObj.id
                console.log(tempObj)
                console.log(tempObj.id)
            }
            
            
        },
        // 明细-编辑
        editDialogClose(){
            
            this.editDialogVisible = false;
        },
        saveEdit(){
            let that = this
            if(that.curObj.TemplateId==1){
                this.$refs.editOftenDialogForm.validate((valid) => {
                    if (!valid) return this.$message.error("关键信息不能为空");
                    if(that.editDialogForm.ItemCount==0){
                        return that.$message.error("数量不正确");
                    }
                    this.showLoading();
                });
                
            }else if(that.curObj.TemplateId==2){
                this.$refs.editTransportDialogForm.validate((valid) => {
                    if (!valid) return this.$message.error("关键信息不能为空");
                    if(that.editDialogForms.SendCount==0){
                        return that.$message.error("数量不正确");
                    }
                    this.showLoadings();
                });
                
            }
        },
         async showLoading() {
            let that = this;
            console.log(this.editDialogForm)
            await that.$http
            .post('ItemDetail/editObj', that.editDialogForm )
            .then(
                (res) => {
                    console.log(res);
                    if (res.data.Code !== "200")
                    return that.$message.error(res.data.Message);
                    this.getItemDetailList();
                    this.editDialogVisible = false;
                },
                (res) => {
                    that.$message.error("远程获取数据失败");
                }
            );
		},
        async showLoadings() {
            let that = this;
            console.log(this.editDialogForms.id)
            let data = {
                SendAddressNewsId: that.editDialogForms.DeliveryName + '-' + that.editDialogForms.DeliveryAddress,
                ConsigneeNewsId: that.editDialogForms.ConsigneeName + '-' + that.editDialogForms.ConsigneeAddress,
				SendCount:that.editDialogForms.SendCount,
				SendUnit:that.editDialogForms.SendUnit,
				id:that.editDialogForms.id,
            }
            await that.$http
            .post('ConsigneeDetail/editObj', data )
            .then(
                (res) => {
                    console.log(res);
                    if (res.data.Code !== "200")
                    return that.$message.error(res.data.Message);
                    this.getItemDetailList();
                    this.editDialogVisible = false;
                },
                (res) => {
                    that.$message.error("远程获取数据失败");
                }
            );
		},

        deleteConfirm(id) {
            this.$confirm("您确实要删除此记录吗, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                    //执行删除方法
                    this.deleteItem(id);
                    })
                    .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消操作",
                    });
                    });
        },
        async deleteItem(id) {
            let that = this;
            let url = '';
            if(that.curObj.TemplateId === 1){
                url = 'ItemDetail/deleteObj'
            }
             if(that.curObj.TemplateId === 2){
                url = 'ConsigneeDetail/deleteObj';
            }
        await that.$http
            .post(url, { id: id })
            .then(
            (res) => {
                if (res.data.Code !== "200") {
                return that.$message.error(res.data.Message);
                }
                that.$message.success(res.data.Message);
                that.getItemDetailList();
            
                
            },
            (res) => {
                that.$message.error("远程操作失败或网络问题");
            }
            );
        },
        //汽车-明细-select
        async showConsignee() {
            let that = this;
            let data ={
                ConsigneeName: '',
				IsEnabled:true,
            }
            await that.$http
            .post('Consignee/GetListByNoPage', data )
            .then(
                (res) => {
                    console.log(res);
                    if (res.data.Code !== "200")
                    return that.$message.error(res.data.Message);
                    // this.getItemDetailList();
                    // this.editDialogVisible = false;
                    that.consigneeList = res.data.Obj
                },
                (res) => {
                    that.$message.error("远程获取数据失败");
                }
            );                             
		},
        async showDelivery() {
            let that = this;
            let data ={
                DeliveryName: '',
				IsEnabled:true,
            }
            await that.$http
            .post('Delivery/GetListByNoPage', data )
            .then(
                (res) => {
                    console.log(res);
                    if (res.data.Code !== "200")
                    return that.$message.error(res.data.Message);
                    // this.getItemDetailList();
                    // this.editDialogVisible = false;
                    // consigneeList
                    that.deliveryList = res.data.Obj
                },
                (res) => {
                    that.$message.error("远程获取数据失败");
                }
            );                             
		},
         //分配-申请
        checkRequest(){
            let that=this;
            that.$confirm("您确实要选择此供应商吗, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(() => {
            //执行添加供应商方法
            that.getAccessToken();
            })
            .catch(() => {
            that.$message({
                type: "info",
                message: "已取消操作",
            });
            });
        },
        async getAccessToken() {
                    var that = this;
                    await that.$http.post("User/getAccessToken").then(
                        (res) => {
                        if (res.data.Code !== "200") {
                            return that.$message.error(res.data.Message);
                        }
                        //that.$message.success(res.data.Message);
                        if (res.data.Obj != null) {
                                that.checkRequestExe(res.data.Obj);
                            }

                        },
                        (res) => {
                        that.$message.error("远程操作失败或网络问题");
                        }
                    );
        },
	
        async checkRequestExe(accessToken){
                    let that = this;
                    let data= {
                            id:that.DemandContent.id,
                            AccessToken: accessToken
                        }
                    //开始提交信息
                    await that.$http.post("ProcurementDemand/requestObj" , data).then(
                        (res) => {
                        if (res.data.Code !== "200") {
                            return that.$message.error(res.data.Message);
                        }
                        that.$message.success(res.data.Message);
                    that.getObj();

                        },
                        (res) => {
                        that.$message.error("远程操作失败或网络问题");
                        }
                    );
        },
        //附件查看
        viewAttachments(){
            this.attachFileDir=this.curObj.ProcurementDemandOtherFileDir;
            if(this.attachFileDir!=null && this.attachFileDir!=""){
                this.fileUrl=this.rootUrl+this.attachFileDir;
                window.open(this.fileUrl);
           }
           else{
            this.$message.error("没有附件");
           }

        },
        flexWidth(prop, tableData, title, num = 0) {
                if (tableData.length === 0) {
                    //表格没数据不做处理
                    return;
                }
                let flexWidth = 0; //初始化表格列宽

                flexWidth = this.$common.flexWidth(prop, tableData, title, num);
                return flexWidth + "px";
            }
        },
      

}
</script>

<style scoped>
.demo-drawer__footer{
     position: fixed;
     bottom: 20px;

}
  
.el-button--primary.is-plain{
    margin-left: 10px;
}
.el-drawer__body>* {
    box-sizing: border-box;
    padding: 0 20px 0 20px;
}
.el-input-group {
  width: 94% ;
}
.el-button--primary-Distr{
  margin-left: 10px;
}
.top {
    padding: 5px 0 5px 0;
    font-size: 14px;
}
.dialog-title{
    border-left: 5px solid rgb(255, 0, 0);
    padding-left: 5px;
    font-weight: 400;
    font-size: 20px;
}
.dialog-title-two{
    margin: 15px 0 15px 0;
}
</style>