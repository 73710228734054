<template>
    <div>
      <el-card>
        <!-- 搜索框区域 -->
        <el-row :gutter="20">
          <el-col :span="15">
            <div>
              <el-input placeholder="请输入查询关键字" v-model="queryInfo.query" clearable @clear="query">
                <el-button slot="append" icon="el-icon-search" @click="query"></el-button>
              </el-input>
            </div>
          </el-col>
          <el-col :span="4">
            <div>
              <el-button type="primary" @click="addDialogVisible = true" icon="el-icon-plus">添加</el-button>
            </div>
          </el-col>
        </el-row>
        <el-table :data="dataList" stripe table-layout='auto' class="tableAuto">
          <el-table-column label="#" type="index"></el-table-column>
          <el-table-column :width="flexWidth('ItemName',dataList,'',20)" label="品项" prop="ItemName"></el-table-column>
          <el-table-column :width="flexWidth('ItemSpec',dataList,'',20)" label="规格" prop="ItemSpec"></el-table-column>
          <el-table-column :width="flexWidth('ItemUnit',dataList,'',20)" label="单位" prop="ItemUnit"></el-table-column>
          <el-table-column class="button_column" label="操作" width="350px">
            <template slot-scope="scope">
              <el-button type="success" size="mini" @click="showEditDialog(scope.row.id)" v-if="scope.row.LoginName!=='admin'">编辑</el-button>
              <el-button type="success" size="mini" v-else disabled>编辑</el-button>
              <el-button type="danger" size="mini" @click="deleteConfirm(scope.row.id)" v-if="scope.row.LoginName!=='admin'">删除</el-button>
              <el-button type="danger" size="mini" v-else disabled>删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination v-if="!isMobile" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totalRecordCount">
        </el-pagination>
        <el-pagination v-else @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total,sizes,prev, pager, next" :total="totalRecordCount">
        </el-pagination>
          <!-- 添加弹出框 -->
        <el-dialog title="添加品项" :visible.sync="addDialogVisible" width="60%" :close="addDialogClose" :close-on-click-modal="false">
          <el-form :model="addDialogForm" :rules="addDialogFormRules" ref="addDialogFormRef" label-width="100px" class="dialog_form">
            <el-form-item label="品项" prop="ItemName">
              <el-input v-model="addDialogForm.ItemName" placeholder="请输入品项"  ></el-input>
            </el-form-item>
            <el-form-item label="规格" prop="ItemSpec">
              <el-input v-model="addDialogForm.ItemSpec" placeholder="请输入规格"  ></el-input>
            </el-form-item>
            <el-form-item label="单位" prop="ItemUnit">
              <el-input v-model="addDialogForm.ItemUnit" placeholder="请输入单位"  ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDialogClose">取 消</el-button>
            <el-button type="primary" @click="saveAdd">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 编辑弹出框 -->
        <el-dialog title="编辑品项" :visible.sync="editDialogVisible" width="60%" :close="editDialogClose" :close-on-click-modal="false">
          <el-form :model="editDialogForm" :rules="addDialogFormRules" ref="editDialogFormRef" label-width="100px" class="dialog_form">
            <el-form-item label="品项" prop="ItemName">
              <el-input v-model="editDialogForm.ItemName" placeholder="请输入品项"  ></el-input>
            </el-form-item>
            <el-form-item label="规格" prop="ItemSpec">
              <el-input v-model="editDialogForm.ItemSpec" placeholder="请输入规格"  ></el-input>
            </el-form-item>
            <el-form-item label="单位" prop="ItemUnit">
              <el-input v-model="editDialogForm.ItemUnit" placeholder="请输入单位"  ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="editDialogClose">取 消</el-button>
            <el-button type="primary" @click="saveEdit">确 定</el-button>
          </span>
        </el-dialog>
  
      </el-card>
    </div>
  </template>
  
  <script>
export default {
  data() {
    return {
      totalRecordCount: 0,
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10,
        // token: window.sessionStorage.getItem("token"),
        // loginname: window.sessionStorage.getItem("loginname"),
      },
      dataList: [],
      addDialogVisible: false,
      editDialogVisible: false,
      authDialogVisible: false,
      addDialogForm: {
        ItemName: "",
        ItemSpec: "",
        ItemUnit: "",
      },
      editDialogForm: {
        ItemName: "",
        ItemSpec: "",
        ItemUnit: "",
      },//编辑框
      companyList: [],
        //表单验证
      addDialogFormRules: {
        ItemName: [
          { required: true, message: "请输入品项", trigger: "blur" },
          {
            min: 1,
            max: 300,
            message: "长度在 1 到 300 个字符",
            trigger: "blur",
          },
        ],
        ItemSpec: [
          { required: true, message: "请输入规格", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        ItemUnit: [
          { required: true, message: "请输入单位", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getList();
    var isMobileTemp=window.sessionStorage.getItem("isMobile");
    if(isMobileTemp=='true'){
      this.isMobile=true;
    }else{
      this.isMobile=false;
    }
  },
  methods: {
    // 展示列表
    async getList() {
      await this.$http
        .get("Item/GetList", { params: this.queryInfo})
        .then(
          (res) => {
            if (res.data.Code !== "200")
              return this.$message.error(res.data.Message);
              this.dataList = res.data.Obj;
              this.totalRecordCount = res.data.TotalCount;
          },
          (res) => {
              this.$message.error("远程获取数据失败");
          }
        );
    },
   
    change(e) {
      this.$forceUpdate();
    },
    // 搜索
    query() {
      this.getList();
    },
    /* 监听 pagesize 改变事件 */
    handleSizeChange(newsize) {
      this.queryInfo.pagesize = newsize;
      this.getList();
    },
    /* 监听 当前页 改变事件 */
    handleCurrentChange(newpage) {
      this.queryInfo.pagenum = newpage;
      this.getList();
    },
    // 添加取消
    addDialogClose() {
      this.addDialogVisible = false;
      this.$refs.addDialogFormRef.resetFields();
    },
    // 供应商添加
    saveAdd() {
       this.$refs.addDialogFormRef.validate((valid) => {
         if (!valid) return this.$message.error("关键信息不能为空");
         this.submitAdd();
      });
    },
    async submitAdd() {
      await this.$http.post("Item/AddObj", this.addDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.addDialogFormRef.resetFields();
          this.addDialogVisible = false;
          this.$message.success(res.data.Message);
          this.getList();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    editDialogClose() {
      this.editDialogForm.roleId = "";
      this.editDialogVisible = false;
      this.$refs.editDialogFormRef.resetFields();
    },
    // 编辑
    showEditDialog(id) {
      var tempObj = null;
      for (var i = 0; i < this.dataList.length; i++) {
        if (this.dataList[i].id === id) tempObj = this.dataList[i];
      }
      this.editDialogForm.id = id;
      this.editDialogForm.ItemName = tempObj.ItemName;
      this.editDialogForm.ItemSpec = tempObj.ItemSpec;
      this.editDialogForm.ItemUnit = tempObj.ItemUnit;
      this.editDialogVisible = true;
    },
    saveEdit() {
        this.$refs.editDialogFormRef.validate((valid) => {
         if (!valid) return this.$message.error("关键信息不能为空");
         this.submitEdit();
       });
    },
    async submitEdit() {
      await this.$http.post("Item/EditObj", this.editDialogForm).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.editDialogFormRef.resetFields();
          this.editDialogVisible = false;
          this.$message.success(res.data.Message);
          this.getList();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    //删除提示框
    deleteConfirm(id) {
      this.$confirm("您确实要删除此记录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //执行删除方法
          this.deleteUser(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //删除请求
    async deleteUser(id) {
      await this.$http.post("Item/DeleteObj/", { id: id }).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$message.success(res.data.Message);
          this.getList();
          this.query();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    flexWidth(prop, tableData, title, num = 0) {
      if (tableData.length === 0) {//表格没数据不做处理
        return;
      }
      let flexWidth = 0;//初始化表格列宽
      
      flexWidth = this.$common.flexWidth(prop, tableData, title, num );
      return flexWidth + 'px';
    },
  },
};
</script>
  
  <style lang="less" scoped>
  .el-table .cell {
    white-space: pre-line;
  }
  </style>